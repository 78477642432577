import { Box, Tooltip, Button, Stack } from "@mui/material";
import React, { useMemo, useState } from "react";
import Text from "../components/typographyUI/Text";
import PageHeader from "../components/pageUI/PageHeader";
import StackRow from "../components/layoutUI/StackRow";
import CustomButton from "../components/formUI/CustomButton";
import ItemCard from "../components/pageUI/ItemCard";
import CloudPng from "../assets/icons/cloud.png";
import StatusFilter from "../components/pageUI/filters/StatusFilter";
import LocationFilter from "../components/pageUI/filters/LocationFilter";
import { motion } from "framer-motion";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ServerPaginationDataGrid from "../components/pageUI/ServerPaginationDataGrid";
import { useSelector } from "react-redux";
import CustomSearchTextField from "../components/formUI/CustomSearchTextField";
import RestoListFilter from "../components/pageUI/filters/RestoListFilter";
import axios from "axios";
import CustomerDetails from "../pages/CustomerDetails";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import CustomFormDialog from "../components/CustomFormDialog";
import { toast } from "react-toastify";
import CustomeUploadExcel from "../components/CustomUploadExcel";
import CustomDialog from "../components/layoutUI/CustomDialog";
import { Close } from "@mui/icons-material";
import { Field, Form, Formik } from "formik";
import CustomTextFieldFormik from "../components/formUI/CustomTextFieldFormik";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import ExcelJS from "exceljs";
import { useSmallScreen } from "../utils/mediaQueries";
import DateRangePicker from "../components/pageUI/filters/DateRangePicker";

const MessStudents = () => {
  // const [tableData, setTableData] = useState([]);
  // const [search, setSearch] = useState("");
  //
  const isSmallScreen = useSmallScreen();
  const [search, setSearch] = useState("");
  const outletData = useSelector((state) => state.auth.user);
  const [outletId, setOutletId] = useState(outletData.outletId);
  const [customerData, setCustomerData] = useState({});
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [openStudentModal, setOpenStudentModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);

  const formattedStartDate = `${currentYear}-${String(currentMonth).padStart(
    2,
    "0"
  )}-01`;
  const formattedEndDate = currentDate.toISOString().split("T")[0];

  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);



  const outletIdNew = useSelector(
    (state) =>
      state.auth.user.messId ||
      state.outledId.outletId ||
      state.auth.user.outletId
  );

  // const generateExcel = (_jsonData = []) => {
  //   const jsonData = _jsonData.map((d) => ({ ...d }));

  //   jsonData.forEach((d) => {
  //     delete d.campusid;
  //     delete d.outletid;
  //     delete d.customerauthuid;
  //     // delete d.message;
  //   });

  //   const ws = XLSX.utils.json_to_sheet(jsonData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Customers");

  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
  //   const data = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });
  //   saveAs(data, "Customers Data");
  // };
  const openCustomerDetails = (data) => {
    setShowCustomerDetails(true);
    setCustomerData(data);
    //
  };

  const columns = useMemo(() => [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.Customer?.customerName}</Text>,
    },

    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 300,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.Customer?.email}</Text>,
    },

    {
      field: "mobile",
      headerName: "Mobile number",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text>{row?.Customer?.mobile}</Text>,
    },
    {
      field: "RSVP1Count",
      headerName: "BreakFast ",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <Text>
          {row?.Customer?.rsvpCount["1"]} | {row?.Customer?.actualCount["1"]} |
          <span
            style={{
              color:
                row?.Customer?.difference["1"] < 0
                  ? "#DC2626"
                  : row?.Customer?.difference["1"] > 0
                  ? "#16A34A"
                  : "inherit",
            }}
          >
            {" "}
            {row?.Customer?.difference["1"]}
          </span>
        </Text>
      ),
    },
    {
      field: "RSVP2Count",
      headerName: "Lunch ",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <Text>
          {row?.Customer?.rsvpCount["2"]} | {row?.Customer?.actualCount["2"]} |
          <span
            style={{
              color:
                row?.Customer?.difference["2"] < 0
                  ? "#DC2626"
                  : row?.Customer?.difference["2"] > 0
                  ? "#16A34A"
                  : "inherit",
            }}
          >
            {" "}
            {row?.Customer?.difference["2"]}
          </span>
        </Text>
      ),
    },
    {
      field: "RSVP3Count",
      headerName: "HighTea ",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <Text>
          {row?.Customer?.rsvpCount["3"]} | {row?.Customer?.actualCount["3"]} |
          <span
            style={{
              color:
                row?.Customer?.difference["3"] < 0
                  ? "#DC2626"
                  : row?.Customer?.difference["3"] > 0
                  ? "#16A34A"
                  : "inherit",
            }}
          >
            {" "}
            {row?.Customer?.difference["3"]}
          </span>
        </Text>
      ),
    },
    {
      field: "RSVP4Count",
      headerName: "Dinner ",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <Text>
          {row?.Customer?.rsvpCount["4"]} | {row?.Customer?.actualCount["4"]} |
          <span
            style={{
              color:
                row?.Customer?.difference["4"] < 0
                  ? "#DC2626"
                  : row?.Customer?.difference["4"] > 0
                  ? "#16A34A"
                  : "inherit",
            }}
          >
            {" "}
            {row?.Customer?.difference["4"]}
          </span>
        </Text>
      ),
    },
    // {
    //   field: "Actual1Count",
    //   headerName: "BreakFast Actual",
    //   flex: 1,
    //   minWidth: 150,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text>{row?.Customer?.actualCount["1"]}</Text>,
    // },
    // {
    //   field: "Actual2Count",
    //   headerName: "Lunch Actual",
    //   flex: 1,
    //   minWidth: 150,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text>{row?.Customer?.actualCount["2"]}</Text>,
    // },
    // {
    //   field: "Actual3Count",
    //   headerName: "HighTea Actual",
    //   flex: 1,
    //   minWidth: 150,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text>{row?.Customer?.actualCount["3"]}</Text>,
    // },
    // {
    //   field: "Actual4Count",
    //   headerName: "Dinner Actual",
    //   flex: 1,
    //   minWidth: 150,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text>{row?.Customer?.actualCount["4"]}</Text>,
    // },
    // {
    //   field: "Difference1Count",
    //   headerName: "BreakFast Diff",
    //   flex: 1,
    //   minWidth: 150,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text>{row?.Customer?.difference["1"]}</Text>,
    // },

    // {
    //   field: "Difference2Count",
    //   headerName: "Lunch Diff",
    //   flex: 1,
    //   minWidth: 150,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text>{row?.Customer?.difference["2"]}</Text>,
    // },

    // {
    //   field: "Difference3Count",
    //   headerName: "HighTea Diff",
    //   flex: 1,
    //   minWidth: 150,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text>{row?.Customer?.difference["3"]}</Text>,
    // },

    // {
    //   field: "Difference4Count",
    //   headerName: "Dinner Diff",
    //   flex: 1,
    //   minWidth: 150,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text>{row?.Customer?.difference["4"]}</Text>,
    // },
    // {
    //   field: "ActualCount",
    //   headerName: "Actual Count",
    //   flex: 1,
    //   minWidth: 50,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text>{row?.Customer?.actualCount}</Text>,
    // },
    // {
    //   field: "Difference",
    //   headerName: "Difference",
    //   flex: 1,
    //   minWidth: 50,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text>{row?.Customer?.difference}</Text>,
    // },
  ]);
  const [isAsc, setIsAsc] = useState(false);

  const normalizeString = (str) =>
    str?.trim()?.toLowerCase()?.replace(/\s+/g, " ");

  const getFilterData = (data) => {
    let _data = data;
    if (search) {
      const searchTerm = normalizeString(search);
      _data = _data?.filter((row) =>
        normalizeString(row?.Customer?.customerName)?.includes(searchTerm)
      );
    }
    return _data;
  };

  const generateExcel = (_jsonData = []) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Students");

    // Define headers
    const headers = [
        'Email',
        'Mobile',
        'Customer Name',
        'RSVP Count',
        'Actual Count',
        'Difference'
    ];

    // Add headers
    worksheet.addRow(headers);

    // Transform and add data
    _jsonData.forEach((data) => {
        const formatCounts = (countObj) => {
            return [
                'Breakfast: ' + countObj["1"],
                'Lunch: ' + countObj["2"],
                'Hightea: ' + countObj["3"],
                'Dinner: ' + countObj["4"]
            ].join('\n');
        };

        const formatDifference = (diffObj) => {
            return [
                'Breakfast: ' + diffObj["1"],
                'Lunch: ' + diffObj["2"],
                'Hightea: ' + diffObj["3"],
                'Dinner: ' + diffObj["4"]
            ].map(text => {
                const value = parseInt(text.split(': ')[1]);
                if (value < 0) return `<red>${text}</red>`;
                if (value > 0) return `<green>${text}</green>`;
                return text;
            }).join('\n');
        };

        const rowData = [
            data.email,
            data.mobile,
            data.customerName,
            formatCounts(data.rsvpCount),
            formatCounts(data.actualCount),
            formatDifference(data.difference)
        ];
        worksheet.addRow(rowData);

        // Apply rich text formatting to the difference column
        const lastRowNumber = worksheet.rowCount;
        const differenceCell = worksheet.getCell(`F${lastRowNumber}`);
        
        // Parse the rich text format and apply colors
        const richTextParts = differenceCell.value.split('\n').map(text => {
            if (text.startsWith('<red>')) {
                // Red color for negative values
                const cleanText = text.replace('<red>', '').replace('</red>', '');
                return {
                    text: cleanText + '\n',
                    font: { color: { argb: 'FFFF0000' } }
                };
            } else if (text.startsWith('<green>')) {
                // Green color for positive values
                const cleanText = text.replace('<green>', '').replace('</green>', '');
                return {
                    text: cleanText + '\n',
                    font: { color: { argb: 'FF008000' } }
                };
            }
            return {
                text: text + '\n',
                font: { color: { argb: '000000' } }
            };
        });

        differenceCell.value = {
            richText: richTextParts
        };
    });

    // Apply bold font to the header row
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
        cell.font = { bold: true };
    });

    // Apply formatting to all rows
    worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 1) { // Skip header row
            // Format count columns (columns 4, 5, and 6)
            [4, 5, 6].forEach(colNumber => {
                const cell = row.getCell(colNumber);
                cell.alignment = {
                    vertical: 'top',
                    wrapText: true
                };
            });
        }
    });

    // Adjust column widths and row heights
    worksheet.columns.forEach((column, index) => {
        if (index === 0) { // Email column
            column.width = 30;
        } else if (index === 1) { // Mobile column
            column.width = 15;
        } else if (index === 2) { // Name column
            column.width = 20;
        } else { // Count columns
            column.width = 25;
        }
    });

    // Set row height for data rows
    worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
            row.height = 90; // Adjust this value as needed
        }
    });

    workbook.xlsx
        .writeBuffer()
        .then((excelBuffer) => {
            const data = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            saveAs(data, "Users_Data.xlsx");
        })
        .catch((error) => {
            console.error("Error generating Excel file:", error);
        });
};

//   const generateExcel = (_jsonData = []) => {
//     const workbook = new ExcelJS.Workbook();
//     const worksheet = workbook.addWorksheet("Students");

//     // Define headers
//     const headers = [
//         'Email',
//         'Mobile',
//         'Customer Name',
//         'RSVP Count',
//         'Actual Count',
//         'Difference'
//     ];

//     // Add headers
//     worksheet.addRow(headers);

//     // Transform and add data
//     _jsonData.forEach((data) => {
//         const formatCounts = (countObj) => {
//             return [
//                 'Breakfast: ' + countObj["1"],
//                 'Lunch: ' + countObj["2"],
//                 'Hightea: ' + countObj["3"],
//                 'Dinner: ' + countObj["4"]
//             ].join('\n');
//         };

//         const formatDifference = (diffObj) => {
//             return [
//                 'Breakfast: ' + diffObj["1"],
//                 'Lunch: ' + diffObj["2"],
//                 'Hightea: ' + diffObj["3"],
//                 'Dinner: ' + diffObj["4"]
//             ].map(text => {
//                 const value = parseInt(text.split(': ')[1]);
//                 return value < 0 ? `<r>${text}</r>` : text;
//             }).join('\n');
//         };

//         const rowData = [
//             data.email,
//             data.mobile,
//             data.customerName,
//             formatCounts(data.rsvpCount),
//             formatCounts(data.actualCount),
//             formatDifference(data.difference)
//         ];
//         worksheet.addRow(rowData);

//         // Apply rich text formatting to the difference column
//         const lastRowNumber = worksheet.rowCount;
//         const differenceCell = worksheet.getCell(`F${lastRowNumber}`);
        
//         // Parse the rich text format and apply colors
//         const richTextParts = differenceCell.value.split('\n').map(text => {
//             if (text.startsWith('<r>')) {
//                 // Remove the tags and create rich text with red color
//                 const cleanText = text.replace('<r>', '').replace('</r>', '');
//                 return {
//                     text: cleanText + '\n',
//                     font: { color: { argb: 'FFFF0000' } }
//                 };
//             }
//             return {
//                 text: text + '\n',
//                 font: { color: { argb: '000000' } }
//             };
//         });

//         differenceCell.value = {
//             richText: richTextParts
//         };
//     });

//     // Apply bold font to the header row
//     worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
//         cell.font = { bold: true };
//     });

//     // Apply formatting to all rows
//     worksheet.eachRow((row, rowNumber) => {
//         if (rowNumber > 1) { // Skip header row
//             // Format count columns (columns 4, 5, and 6)
//             [4, 5, 6].forEach(colNumber => {
//                 const cell = row.getCell(colNumber);
//                 cell.alignment = {
//                     vertical: 'top',
//                     wrapText: true
//                 };
//             });
//         }
//     });

//     // Adjust column widths and row heights
//     worksheet.columns.forEach((column, index) => {
//         if (index === 0) { // Email column
//             column.width = 30;
//         } else if (index === 1) { // Mobile column
//             column.width = 15;
//         } else if (index === 2) { // Name column
//             column.width = 20;
//         } else { // Count columns
//             column.width = 25;
//         }
//     });

//     // Set row height for data rows
//     worksheet.eachRow((row, rowNumber) => {
//         if (rowNumber > 1) {
//             row.height = 90; // Adjust this value as needed
//         }
//     });

//     workbook.xlsx
//         .writeBuffer()
//         .then((excelBuffer) => {
//             const data = new Blob([excelBuffer], {
//                 type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
//             });
//             saveAs(data, "Users_Data.xlsx");
//         })
//         .catch((error) => {
//             console.error("Error generating Excel file:", error);
//         });
// };

//   const generateExcel = (_jsonData = []) => {
//     const workbook = new ExcelJS.Workbook();
//     const worksheet = workbook.addWorksheet("Students");

//     // Define headers
//     const headers = [
//         'Email',
//         'Mobile',
//         'Customer Name',
//         'RSVP Count',
//         'Actual Count',
//         'Difference'
//     ];

//     // Add headers
//     worksheet.addRow(headers);

//     // Transform and add data
//     _jsonData.forEach((data) => {
//         const formatCounts = (countObj) => {
//             return [
//                 'Breakfast: ' + countObj["1"],
//                 'Lunch: ' + countObj["2"],
//                 'Hightea: ' + countObj["3"],
//                 'Dinner: ' + countObj["4"]
//             ].join('\n');
//         };

//         const rowData = [
//             data.email,
//             data.mobile,
//             data.customerName,
//             formatCounts(data.rsvpCount),
//             formatCounts(data.actualCount),
//             formatCounts(data.difference)
//         ];
//         worksheet.addRow(rowData);
//     });

//     // Apply bold font to the header row
//     worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
//         cell.font = { bold: true };
//     });

//     // Apply formatting to all rows
//     worksheet.eachRow((row, rowNumber) => {
//         if (rowNumber > 1) { // Skip header row
//             // Format count columns (columns 4, 5, and 6)
//             [4, 5, 6].forEach(colNumber => {
//                 const cell = row.getCell(colNumber);
//                 cell.alignment = {
//                     vertical: 'top',
//                     wrapText: true
//                 };
//             });
//         }
//     });

//     // Adjust column widths and row heights
//     worksheet.columns.forEach((column, index) => {
//         if (index === 0) { // Email column
//             column.width = 30;
//         } else if (index === 1) { // Mobile column
//             column.width = 15;
//         } else if (index === 2) { // Name column
//             column.width = 20;
//         } else { // Count columns
//             column.width = 25;
//         }
//     });

//     // Set row height for data rows
//     worksheet.eachRow((row, rowNumber) => {
//         if (rowNumber > 1) {
//             row.height = 90; // Adjust this value as needed
//         }
//     });

//     workbook.xlsx
//         .writeBuffer()
//         .then((excelBuffer) => {
//             const data = new Blob([excelBuffer], {
//                 type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
//             });
//             saveAs(data, "Users_Data.xlsx");
//         })
//         .catch((error) => {
//             console.error("Error generating Excel file:", error);
//         });
// };



//     const workbook = new ExcelJS.Workbook();
//     const worksheet = workbook.addWorksheet("Students");

//     // Define headers
//     const headers = [
//         'Email',
//         'Mobile',
//         'Customer Name',
//         // RSVP counts
//         'Breakfast RSVP',
//         'Lunch RSVP',
//         'Hightea RSVP',
//         'Dinner RSVP',
//         // Actual counts
//         'Breakfast Actual',
//         'Lunch Actual',
//         'Hightea Actual',
//         'Dinner Actual',
//         // Differences
//         'Breakfast Difference',
//         'Lunch Difference',
//         'Hightea Difference',
//         'Dinner Difference'
//     ];

//     // Add headers
//     worksheet.addRow(headers);

//     // Transform and add data
//     _jsonData.forEach((data) => {
//         const rowData = [
//             data.email,
//             data.mobile,
//             data.customerName,
//             // RSVP counts
//             data.rsvpCount["1"],
//             data.rsvpCount["2"],
//             data.rsvpCount["3"],
//             data.rsvpCount["4"],
//             // Actual counts
//             data.actualCount["1"],
//             data.actualCount["2"],
//             data.actualCount["3"],
//             data.actualCount["4"],
//             // Differences
//             data.difference["1"],
//             data.difference["2"],
//             data.difference["3"],
//             data.difference["4"]
//         ];
//         worksheet.addRow(rowData);
//     });

//     // Apply bold font to the header row
//     worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
//         cell.font = { bold: true };
//     });

//     // Add some basic styling
//     worksheet.columns.forEach((column, index) => {
//         column.width = 15;
        
//         // Make email column wider
//         if (index === 0) column.width = 30;
//         // Make name column wider
//         if (index === 2) column.width = 20;
//     });

//     // Add colors to distinguish between RSVP, Actual, and Difference sections
//     worksheet.getRow(1).eachCell((cell, colNumber) => {
//         if (colNumber >= 4 && colNumber <= 7) {
//             // RSVP columns
//             cell.fill = {
//                 type: 'pattern',
//                 pattern: 'solid',
//                 fgColor: { argb: 'FFE6F3FF' }
//             };
//         } else if (colNumber >= 8 && colNumber <= 11) {
//             // Actual columns
//             cell.fill = {
//                 type: 'pattern',
//                 pattern: 'solid',
//                 fgColor: { argb: 'FFE6FFE6' }
//             };
//         } else if (colNumber >= 12) {
//             // Difference columns
//             cell.fill = {
//                 type: 'pattern',
//                 pattern: 'solid',
//                 fgColor: { argb: 'FFFFE6E6' }
//             };
//         }
//     });

//     workbook.xlsx
//         .writeBuffer()
//         .then((excelBuffer) => {
//             const data = new Blob([excelBuffer], {
//                 type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
//             });
//             saveAs(data, "Users_Data.xlsx");
//         })
//         .catch((error) => {
//             console.error("Error generating Excel file:", error);
//         });
// };

//   const generateExcel = (_jsonData = []) => {
//     const workbook = new ExcelJS.Workbook();
//     const worksheet = workbook.addWorksheet("Students");

//     // Meal type mapping
//     const mealTypes = {
//         "1": "Breakfast",
//         "2": "Lunch",
//         "3": "Hightea",
//         "4": "Dinner"
//     };

//     // Transform the data
//     const transformedData = _jsonData.map(data => {
//         const transformed = { ...data };
        
//         // Transform rsvpCount
//         if (transformed.rsvpCount) {
//             transformed.rsvpCount = Object.entries(transformed.rsvpCount)
//                 .map(([key, value]) => `${mealTypes[key]}: ${value}`)
//                 .join(", ");
//         }
        
//         // Transform actualCount
//         if (transformed.actualCount) {
//             transformed.actualCount = Object.entries(transformed.actualCount)
//                 .map(([key, value]) => `${mealTypes[key]}: ${value}`)
//                 .join(", ");
//         }
        
//         // Transform difference
//         if (transformed.difference) {
//             transformed.difference = Object.entries(transformed.difference)
//                 .map(([key, value]) => `${mealTypes[key]}: ${value}`)
//                 .join(", ");
//         }
        
//         return transformed;
//     });

//     // Adding header row
//     if (transformedData.length > 0) {
//         const headers = Object.keys(transformedData[0]);
//         worksheet.addRow(headers);
//     }

//     // Adding data rows
//     transformedData.forEach((data) => {
//         worksheet.addRow(Object.values(data));
//     });

//     // Apply bold font to the header row
//     worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
//         cell.font = { bold: true };
//     });

//     // Adjusting column widths - increased for longer text
//     worksheet.columns.forEach((column) => {
//         column.width = 40; // Increased width to accommodate longer text
//     });

//     workbook.xlsx
//         .writeBuffer()
//         .then((excelBuffer) => {
//             const data = new Blob([excelBuffer], {
//                 type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
//             });
//             saveAs(data, "Users_Data.xlsx");
//         })
//         .catch((error) => {
//             console.error("Error generating Excel file:", error);
//         });
// };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        <PageHeader
          secondary
          title={"Users"}
          subTitle={"View and update your users details"}
        />
        {isSmallScreen ? (
          <Stack spacing={2} width="100%">
            {/* First row: Full-width search bar */}
            <CustomSearchTextField
              fullWidth
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <DateRangePicker
              setFrom={setStartDate}
              setTo={setEndDate}
              fromDate={startDate}
              toDate={endDate}
              defaultValue
            />

            {/* Second row: Add Student button */}
            <CustomButton
              style={{ maxWidth: "50%" }}
              onClick={() => setOpenStudentModal(true)}
            >
              + Add User
            </CustomButton>

            {/* Third row: Upload Excel and Generate Excel buttons */}
            <Stack direction="row" spacing={2} style={{ marginBottom: "1rem" }}>
              <CustomButton
                textColor="#4F46E5"
                bgColor="#EEF2FF"
                variant="outlined"
                // startIcon={<CloudDownloadOutlinedIcon />}
                onClick={() => {
                  setOpen(true);
                  // axios
                  //   .get(
                  //     process.env.REACT_APP_BACKEND_BASE_URL +
                  //       "customer/getCustomer/" +
                  //       outletIdNew
                  //   )
                  //   .then((res) => {
                  //     //
                  //     // setTableData(res.data.data);
                  //     generateExcel(res.data.data);
                  //   })
                  //   .catch((error) => {});
                }}
              >
                <img
                  src={CloudPng}
                  alt="upload"
                  style={{
                    width: "2rem",
                    height: "2rem",
                    marginRight: "0.5rem",
                  }}
                />
                Upload Excel
              </CustomButton>
              <CustomButton
                textColor="#4F46E5"
                bgColor="#EEF2FF"
                variant="outlined"
                startIcon={<CloudDownloadOutlinedIcon />}
                onClick={() => {
                  setLoading(true);
                  axios
                    .get(
                      process.env.REACT_APP_BACKEND_BASE_URL +
                        `mess/mwl/getWhitelistedUsersExcel/${outletIdNew}?startDateTime=${startDate}&endDateTime=${endDate}`
                    )
                    .then((res) => {
                      // console.log("dataaa ",res?.data?.data);
                      generateExcel(res?.data?.data);
                    })
                    .catch((error) => {})
                    .finally(() => setLoading(false));
                }}
              >
                {loading ? "Generating..." : "Generate Excel"}
              </CustomButton>
            </Stack>
          </Stack>
        ) : (
          <StackRow
            gap={"10px"}
            style={{ display: "flex" }}
            between
            marginBottom="10px"
          >
            {/* <LocationFilter /> */}
            <CustomSearchTextField
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <StackRow center gap="14px">
              {/* <RestoListFilter
              onClick={(label, id) => {
                setOutletId(id);
              }}
            /> */}
              {/* <StatusFilter
              label="Sort by"
              onClick={(_, id) => setIsAsc(id == 1)}
            /> */}
              <CustomButton onClick={() => setOpenStudentModal(true)}>
                + Add User
              </CustomButton>
              <DateRangePicker
                setFrom={setStartDate}
                setTo={setEndDate}
                fromDate={startDate}
                toDate={endDate}
                defaultValue={true}
              />

              <CustomButton
                textColor="#4F46E5"
                bgColor="#EEF2FF"
                variant="outlined"
                // startIcon={<CloudDownloadOutlinedIcon />}
                onClick={() => {
                  setOpen(true);
                  // axios
                  //   .get(
                  //     process.env.REACT_APP_BACKEND_BASE_URL +
                  //       "customer/getCustomer/" +
                  //       outletIdNew
                  //   )
                  //   .then((res) => {
                  //     //
                  //     // setTableData(res.data.data);
                  //     generateExcel(res.data.data);
                  //   })
                  //   .catch((error) => {});
                }}
              >
                <img
                  src={CloudPng}
                  alt="upload"
                  style={{
                    width: "2rem",
                    height: "2rem",
                    marginRight: "0.5rem",
                  }}
                />
                Upload Excel
              </CustomButton>
              <CustomButton
                textColor="#4F46E5"
                bgColor="#EEF2FF"
                variant="outlined"
                startIcon={<CloudDownloadOutlinedIcon />}
                onClick={() => {
                  setLoading(true);
                  axios
                    .get(
                      process.env.REACT_APP_BACKEND_BASE_URL +
                        `mess/mwl/getWhitelistedUsersExcel/${outletIdNew}?startDateTime=${startDate}&endDateTime=${endDate}`
                    )
                    .then((res) => {
                      // console.log("dataaa ",res?.data?.data);
                      generateExcel(res?.data?.data);
                    })
                    .catch((error) => {})
                    .finally(() => setLoading(false));
                }}
              >
                {loading ? "Generating..." : "Generate Excel"}
              </CustomButton>
            </StackRow>
          </StackRow>
        )}

        {outletIdNew && (
          <CustomDataGrid
            name="student-table"
            height={"490px"}
            columns={columns}
            rowId="customerAuthUID"
            url={
              process.env.REACT_APP_BACKEND_BASE_URL +
              "mess/mwl/getWhitelistedUsers/" +
              outletIdNew +
              `?startDateTime=${startDate}&endDateTime=${endDate}`
            }
            labelName={"Students"}
            deleteOption
            deleteUrl={
              process.env.REACT_APP_BACKEND_BASE_URL +
              `mess/mwl/deleteWhitelistedUser?outletId=${outletIdNew}&cuid=`
            }
            deleteItem
            noEditAction={true}
            // menuOnClick={({ id, label }, row) => {
            //   if (label === "Edit") {
            //     navigate("/mess/student/" + row?.customerAuthUID);
            //   }
            // }}
            filterFn={getFilterData}
          />
        )}
      </Box>
      {showCustomerDetails && (
        <CustomerDetails
          customerData={customerData}
          setShowCustomerDetails={setShowCustomerDetails}
        />
      )}

      {open && (
        <CustomeUploadExcel
          postUrl="pass here url"
          onSubmit={(file) => {
            toast.success("New Users Added");
          }}
          width={"600px"}
          open={open}
          setOpen={() => {
            setOpen(false);
          }}
        ></CustomeUploadExcel>
      )}

      {openStudentModal && (
        <CustomDialog dontClose open={true} sx={{ width: "80rem" }}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <PageHeader secondary title={`User Details`} />
            <Close
              style={{ cursor: "pointer", fontSize: "2rem" }}
              onClick={() => setOpenStudentModal(false)}
            />
          </Box>

          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              outletId: outletIdNew,
            }}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                errors.email = "Email is required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              console.log("student details ", values);

              try {
                const res = await axios.post(
                  process.env.REACT_APP_BACKEND_BASE_URL +
                    "mess/mwl/whiteListUser",

                  values
                );
                console.log("API response:", res);
                setOpenStudentModal(false);
                if (res?.data?.success) {
                  toast.success("Student Added Successfully");
                  window.location.reload(); // Refresh the page
                } else {
                  toast.error(res?.data?.message);
                }
              } catch (error) {
                console.error("API error:", error);
              }
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    marginTop: "16px",
                  }}
                >
                  <CustomTextFieldFormik
                    label="User Name"
                    placeholder="Enter User Name"
                    name="name"
                  />
                  <CustomTextFieldFormik
                    label="Email"
                    placeholder="Enter Email"
                    required
                    name="email"
                  />
                  <CustomTextFieldFormik
                    label="Mobile Number"
                    placeholder="Enter Mobile Number"
                    name="phone"
                  />

                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      style={{
                        height: "35px",
                        // width: "8rem",
                      }}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </CustomButton>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </CustomDialog>
      )}
    </motion.div>
  );
};

export default MessStudents;
