import React from "react";

const ReportSvg = () => {
  return (
    <svg 
      version="1.1" 
      id="Uploaded to svgrepo.com" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      width="26" 
      height="26" 
      viewBox="0 0 32 32" 
      xmlSpace="preserve" 
      fill="#64748B" 
      stroke="#64748B" 
      strokeWidth="0.00032"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <style type="text/css">{`.linesandangles_een{fill:#64748B;}`}</style>
        <path 
          className="linesandangles_een" 
          d="M24,7V5H4v18c0,2.209,1.791,4,4,4h16c2.209,0,4-1.791,4-4V7H24z M26,23c0,1.105-0.895,2-2,2H8 c-1.105,0-2-0.895-2-2V7h16v16h2V9h2V23z M14,9H8v6h6V9z M12,13h-2v-2h2V13z M16,9h4v2h-4V9z M16,13h4v2h-4V13z M8,17h12v2H8V17z M8,21h12v2H8V21z"
        />
      </g>
    </svg>
  );
};

export default ReportSvg;
