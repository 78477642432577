import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  Button,
  Rating,
  Tooltip,
  Stack,
  IconButton,
  TextField,
} from "@mui/material";
import { motion } from "framer-motion";
import { ResponsivePie } from "@nivo/pie";
import Headline1 from "../components/typographyUI/Headline1";
import Text from "../components/typographyUI/Text";
import PaperBox from "../components/layoutUI/PaperBox";
import { useMediumScreen, useSmallScreen } from "../utils/mediaQueries";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import axios from "axios";
import { useSelector } from "react-redux";

const RatingsDashboard = () => {
  const outletId = useSelector(
    (state) => state.auth.user.messId || state.outledId.outletId
  );
  const isSmall = useSmallScreen();
  const [ratings, setRatings] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [replyStates, setReplyStates] = useState({});
  const [replyTexts, setReplyTexts] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
   

    fetchDashboardData();
  }, [outletId]);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/getReviewsDashboard/${outletId}`
      );
      console.log(response.data.data);

      if (response.data.success) {
        setRatings(response.data.data.ratings);
        setFeedbacks(response.data.data.feedbacks);
        setAverageRating(response.data.data.averageRating);
        setTotalReviews(response.data.data.totalReviews);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  // Transform ratings data for pie chart
  const pieData = ratings?.map((rating) => ({
    id: `${rating.stars} stars`,
    value: rating.count,
    color: rating.color,
  }));

  const handleReplyClick = (reviewId) => {
    setReplyStates((prev) => ({
      ...prev,
      [reviewId]: !prev[reviewId],
    }));
  };

  const handleReplyChange = (reviewId, value) => {
    setReplyTexts((prev) => ({
      ...prev,
      [reviewId]: value,
    }));
  };

  const handleSendReply = async (reviewId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/giveReplyForReview`,
        {
          reviewId,
          reply: replyTexts[reviewId],
          outletId,
        }
      );

      if (response.data.success) {
        setReplyStates((prev) => ({
          ...prev,
          [reviewId]: false,
        }));
        setReplyTexts((prev) => ({
          ...prev,
          [reviewId]: "",
        }));
      }
    } catch (error) {
      console.error("Error sending reply:", error);
    } finally {
      setLoading(false);
      fetchDashboardData();
    }
  };

  const handleCloseReply = (reviewId) => {
    setReplyStates((prev) => ({
      ...prev,
      [reviewId]: false,
    }));
    setReplyTexts((prev) => ({
      ...prev,
      [reviewId]: "",
    }));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmall ? "column" : "row",
            alignItems: isSmall ? "stretch" : "flex-start",
            justifyContent: "space-between",
            mb: 4,
            gap: 2,
          }}
        >
          <Box>
            <Headline1>Ratings Dashboard</Headline1>
            <Text sx={{ fontSize: isSmall ? "1.1rem" : "1.3rem" }}>
              Track and analyze customer feedback and ratings
            </Text>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isSmall ? "column" : "row",
            gap: 3,
            mb: 4,
          }}
        >
          <PaperBox padding={"1.7rem"} sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Average Rating
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating
                value={averageRating}
                precision={0.5}
                readOnly
                size={isSmall ? "medium" : "large"}
              />
              <Typography
                variant={isSmall ? "h5" : "h4"}
                sx={{ ml: 2, fontWeight: 600 }}
              >
                {averageRating?.toFixed(1)}
              </Typography>
            </Box>
          </PaperBox>

          <PaperBox padding={"1.7rem"} sx={{ flex: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Total Reviews
              </Typography>
              <Tooltip title="Total number of reviews received">
                <IconButton size="small">
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
            <Typography variant="h4">{totalReviews}</Typography>
          </PaperBox>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isSmall ? "column" : "row",
            gap: 3,
          }}
        >
          <PaperBox
            padding={"1.7rem"}
            sx={{
              width: isSmall ? "100%" : "35%",
            }}
          >
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
              Reviews Summary
            </Typography>
            <Box sx={{ height: isSmall ? 150 : 200 }}>
              <ResponsivePie
                data={pieData}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                innerRadius={0.6}
                padAngle={0.5}
                cornerRadius={3}
                colors={pieData?.map((d) => d.color)}
                enableArcLinkLabels={false}
                arcLinkLabelsColor={{ from: "color" }}
                arcLinkLabelsThickness={2}
                enableArcLabels={!isSmall}
                arcLabelsTextColor="#ffffff"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              {ratings?.map((rating) => (
                <Box
                  key={rating.stars}
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <Box>
                    {rating.stars}{" "}
                    <Rating
                      value={1}
                      readOnly
                      size="small"
                      sx={{ mr: 1 }}
                      max={1}
                    />
                  </Box>
                  <Typography sx={{ minWidth: 50 }}>
                    ({rating.count})
                  </Typography>
                  <Box sx={{ flex: 1, ml: 2 }}>
                    <Box
                      sx={{
                        height: 6,
                        bgcolor: "#f0f0f0",
                        borderRadius: 4,
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          width: `${
                            (rating.count /
                              Math.max(...ratings?.map((r) => r.count))) *
                            100
                          }%`,
                          height: "100%",
                          bgcolor: rating.color,
                          borderRadius: 4,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </PaperBox>

          <PaperBox padding={"1.7rem"} sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Recent Feedbacks
              </Typography>
              <Box
                sx={{
                  ml: 1,
                  bgcolor: "primary.main",
                  color: "white",
                  borderRadius: "12px",
                  px: 0.1,
                  py: 0.5,
                  fontSize: "0.9rem",
                  fontWeight: 600,
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "20px",
                }}
              >
                {feedbacks?.length}
              </Box>
            </Box>
            <Box
              sx={{ maxHeight: isSmall ? "400px" : "500px", overflow: "auto" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {feedbacks?.map((feedback) => (
                  <PaperBox
                    key={feedback.reviewId}
                    padding={isSmall ? "1.2rem" : "1.7rem"}
                    sx={{
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "translateY(-2px)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: isSmall ? "column" : "row",
                        justifyContent: "space-between",
                        mb: 2,
                        gap: 2,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {feedback?.photo ? (
                          <Avatar
                            src={feedback.photo}
                            sx={{
                              width: isSmall ? 40 : 48,
                              height: isSmall ? 40 : 48,
                              mr: 2,
                            }}
                          />
                        ) : (
                          <Avatar
                            sx={{
                              width: isSmall ? 40 : 48,
                              height: isSmall ? 40 : 48,
                              mr: 2,
                              bgcolor: "#bdbdbd",
                            }}
                          >
                            {feedback.name.charAt(0)}
                          </Avatar>
                        )}
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              fontSize: isSmall ? "0.9rem" : "1.1rem",
                            }}
                          >
                            {feedback.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: isSmall ? "0.8rem" : "0.9rem" }}
                          >
                            {feedback.phone}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ textAlign: isSmall ? "left" : "right" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "primary.main", fontWeight: 500 }}
                        >
                          {new Date(feedback.date)
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })
                            .replace(",", "")}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.secondary",
                            bgcolor: "#f5f5f5",
                            px: 1.5,
                            py: 0.5,
                            borderRadius: "16px",
                            display: "inline-block",
                          }}
                        >
                          {feedback.mealType === 1
                            ? "Breakfast"
                            : feedback.mealType === 2
                            ? "Lunch"
                            : feedback.mealType === 3
                            ? "High Tea"
                            : feedback.mealType === 4
                            ? "Dinner"
                            : feedback.mealType}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 2,
                        color: "black",
                        p: isSmall ? 1.5 : 2,
                        bgcolor: "#f8f9fa",
                        borderRadius: "8px",
                        fontSize: isSmall ? "0.9rem" : "1.1rem",
                        lineHeight: 1.6,
                        fontWeight: 500,
                      }}
                    >
                      {feedback.comment}
                    </Typography>
                    {feedback.reply && (
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2,
                          color: "text.secondary",
                          p: isSmall ? 1.5 : 2,
                          bgcolor: "#e0f7fa",
                          borderRadius: "8px",
                          fontSize: isSmall ? "0.8rem" : "1rem",
                          lineHeight: 1.4,
                          fontWeight: 400,
                        }}
                      >
                        <strong>Reply:</strong> {feedback.reply}
                      </Typography>
                    )}
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      {replyStates[feedback.reviewId] && (
                        <Box sx={{ mt: 2 }}>
                          <TextField
                            fullWidth
                            multiline
                            rows={3}
                            variant="outlined"
                            placeholder="Type your reply..."
                            value={replyTexts[feedback.reviewId] || ""}
                            onChange={(e) =>
                              handleReplyChange(feedback.reviewId, e.target.value)
                            }
                            sx={{ mb: 2 }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: 2,
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => handleCloseReply(feedback.reviewId)}
                              sx={{
                                textTransform: "none",
                                borderRadius: "8px",
                                px: isSmall ? 1 : 2,
                                py: 1,
                              }}
                            >
                              Close
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => handleSendReply(feedback.reviewId)}
                              disabled={!replyTexts[feedback.reviewId]}
                              sx={{
                                textTransform: "none",
                                borderRadius: "8px",
                                px: isSmall ? 1 : 2,
                                py: 1,
                              }}
                            >
                              {loading ? "Sending..." : "Send Reply"}
                            </Button>
                          </Box>
                        </Box>
                      )}
                      {!replyStates[feedback.reviewId] && (
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => handleReplyClick(feedback.reviewId)}
                            sx={{
                              textTransform: "none",
                              borderRadius: "8px",
                              px: isSmall ? 1 : 2,
                              py: 1,
                              width: isSmall ? "100%" : "auto",
                              fontSize: isSmall ? "0.9rem" : "1rem",
                            }}
                          >
                            View And Reply
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </PaperBox>
                ))}
              </Box>
            </Box>
          </PaperBox>
        </Box>
      </Box>
    </motion.div>
  );
};

export default RatingsDashboard;
