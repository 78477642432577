import React, { useMemo, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Rating,
  Button,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FilterListIcon from "@mui/icons-material/FilterList";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { motion } from "framer-motion";
import Headline1 from "../components/typographyUI/Headline1";
import Text from "../components/typographyUI/Text";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import PaperBox from "../components/layoutUI/PaperBox";
import axios from "axios";
import { useMediumScreen, useSmallScreen } from "../utils/mediaQueries";
import { useSelector } from "react-redux";
import DateRangePicker from "../components/pageUI/filters/DateRangePicker";

const Feedbacks = () => {
  const isSmall = useSmallScreen();
  const [feedbacks, setFeedbacks] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);

  const formattedStartDate = `${currentYear}-${String(currentMonth).padStart(
    2,
    "0"
  )}-01`;
  const formattedEndDate = currentDate.toISOString().split("T")[0];

  const [fromDate, setFromDate] = useState(formattedStartDate);
   
  const [toDate, setToDate] = useState(formattedEndDate);

  const outletId = useSelector(
    (state) => state.auth.user.messId || state.outledId.outletId
  );

  useEffect(() => {
    const fetchFeedbackData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/getReviewsData/${outletId}?fromDate=${fromDate}&toDate=${toDate}`
        );

        if (response?.data?.success) {
          const { feedbacks, averageRating, totalReviews } = response.data.data;
          setFeedbacks(feedbacks);
          setAverageRating(averageRating);
          setTotalReviews(totalReviews);
        }
      } catch (error) {
        console.error("Error fetching feedback data:", error);
      }
    };

    fetchFeedbackData();
  }, [outletId, fromDate, toDate]);

  const columns = useMemo(
    () => [
      {
        field: "date",
        headerName: "Date",
        flex: 1,
        minWidth: 120,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem", py: 1 }} bold>
            {new Date(row.date).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }).replace(/\//g, '-')}
          </Text>
        ),
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 150,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem", py: 1 }} bold>
            {row.name}
          </Text>
        ),
      },
      {
        field: "mealType",
        headerName: "Meal Type",
        flex: 1,
        minWidth: 120,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem", py: 1 }} bold>
            {row.mealType === 1 ? 'Breakfast' : 
             row.mealType === 2 ? 'Lunch' :
             row.mealType === 3 ? 'High Tea' :
             row.mealType === 4 ? 'Dinner' : row.mealType}
          </Text>
        ),
      },
      {
        field: "rating",
        headerName: "Rating",
        flex: 1,
        minWidth: 150,
        renderCell: ({ row }) => (
          <Stack direction="row" spacing={1} alignItems="center" sx={{ py: 1 }}>
            <Typography fontSize="1.2rem">{row.rating.toFixed(1)}</Typography>
            <Rating value={row.rating} readOnly size="medium" />
          </Stack>
        ),
      },
      {
        field: "feedback",
        headerName: "Feedback",
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem", py: 1 }} bold>
            {row.feedback}
          </Text>
        ),
      },
      {
        field: "userAvgRating",
        headerName: "User Average Rating",
        flex: 1,
        minWidth: 180,
        renderCell: ({ row }) => (
          <Stack direction="row" spacing={1} alignItems="center" sx={{ py: 1 }}>
            <Typography fontSize="1.2rem">{row?.userAvgRating.toFixed(1)}</Typography>
            <Rating precision={0.1} value={row.userAvgRating} readOnly size="medium" />
          </Stack>
        ),
      },
    ],
    []
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            mb: 4,
            flexDirection: isSmall ? "column" : "row",
            gap: isSmall ? 2 : 0,
          }}
        >
          <Box>
            <Headline1>Meal Feedbacks</Headline1>
            <Text sx={{ fontSize: "1.3rem" }}>Ratings and Reviews</Text>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: isSmall ? "100%" : "auto",
            }}
          >
             <DateRangePicker
              setFrom={setFromDate}
              setTo={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              defaultValue
            />
          </Stack>
        </Box>

        <Stack
          direction={isSmall ? "column" : "row"}
          spacing={4}
          mb={4}
          sx={{
            "& > *": {
              width: isSmall ? "100%" : "auto",
            },
          }}
        >
          <PaperBox padding={"1.7rem"} sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Average Rating
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <Rating
                value={averageRating}
                precision={0.1}
                readOnly
                size={isSmall ? "small" : "large"}
              />
              <Typography variant="h6">({averageRating?.toFixed(1)}/5)</Typography>
            </Stack>
          </PaperBox>

          <PaperBox padding={"1.7rem"} sx={{ flex: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Total Reviews
              </Typography>
              <Tooltip title="Total number of reviews received">
                <IconButton size="small">
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
            <Typography variant="h4">{totalReviews}</Typography>
          </PaperBox>
        </Stack>

        <Box
          sx={{
            width: "100%",
            overflowX: "scroll",
          }}
        >
          <CustomDataGrid
            name="feedbacks"
            rows={feedbacks}
            columns={columns}
            getRowId={(row) => row.date + row.name + row.mealType}
            autoHeight={true}
            noActions={true}
            getRowHeight={() => "auto"}
          />
        </Box>
      </Box>
    </motion.div>
  );
};

export default Feedbacks;
