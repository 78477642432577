import React, { useEffect, useState } from "react";
import Headline2 from "../components/typographyUI/Headline2";
import Text from "../components/typographyUI/Text";
import {
  Grid,
  Stack,
  Box,
  Button,
  Divider,
  ButtonGroup,
  LinearProgress,
  Skeleton,
  useMediaQuery,
  Tabs,
  Tab,
  Badge,
} from "@mui/material";
import DashboardCard from "../components/pageUI/dashboard/DashboardCard";
import PaperBox from "../components/layoutUI/PaperBox";
import Headline4 from "../components/typographyUI/Headline4";
import LineChart from "../components/chartUI/LineChart";
import Headline3 from "../components/typographyUI/Headline3";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Publish from "./Publish";
import axios from "axios";
import { useQuery } from "react-query";
import BarChart2 from "../components/chartUI/BarChart2";
import StackRow from "../components/layoutUI/StackRow";
import CustomButton from "../components/formUI/CustomButton";
import FoodImg from "../assets/images/image 4.png";
import ItemCard from "../components/pageUI/ItemCard";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import CustomSwitch from "../components/formUI/CustomSwitch";
import RestoListFilter from "../components/pageUI/filters/RestoListFilter";
import { updatePetPoojaConfigs } from "../Services/menuService";
import { authLogin } from "../Redux Store/Slices/auth";
import { toast } from "react-toastify";
import {
  updateOutletData,
  updateOutletOpenStatus,
} from "../Services/generalService";
import StatusFilter from "../components/pageUI/filters/StatusFilter";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import CustomSearchTextField from "../components/formUI/CustomSearchTextField";
import { useMemo } from "react";
import PieChart from "../components/chartUI/PieChart";
import CustomDialog from "../components/layoutUI/CustomDialog";
import { ArrowBack, Close } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useGetApis } from "../hooks/useGetApis";
import PageHeader from "../components/pageUI/PageHeader";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import Headline1 from "../components/typographyUI/Headline1";
import ReceiptIcon from "@material-ui/icons/Receipt";

import {
  useSmallScreen,
  isMobile,
  useMediumScreen,
} from "../utils/mediaQueries";
import { setCheckIsMess } from "../Redux Store/Slices/duplicateOutlet";
import Bot from "../components/ChatBot/Bot";

const Dashboard = () => {
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const outletDetails = useSelector((state) => state.auth.user);
  const [outletId, setOutletId] = useState(outletDetails.outletId);
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const { itemGetUrl } = useGetApis();
  const [search1, setSearch1] = useState("");
  const [orderOpen, setOrderOpen] = useState(false);
  const [orderDetail, setOrderDetail] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [orderType, setOrderType] = useState("Dine In");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    JSON.parse(localStorage.getItem("checkIsMess")) == true ? 1 : 0 || 0
  );
  const pendingOrders = useSelector(
    (state) => state.pendingOrders.pendingOrders
  );

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);

    if (newValue === 0) {
      dispatch(setCheckIsMess(false));
      navigate("/");
    } else {
      dispatch(setCheckIsMess(true));
      navigate("/mdashboard");
    }
  };

  

  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };

  const normalizeString = (str) =>
    str?.trim()?.toLowerCase()?.replace(/\s+/g, " ");
  const getFilterData1 = (data) => {
    let _data = data;
    if (search1) {
      const searchTerm = normalizeString(search1);
      _data = _data?.filter((row) =>
        normalizeString(row?.itemname)?.includes(searchTerm)
      );
    }
    return _data;
  };

  const handleQuantityChange = (itemid, change) => {
    setQuantities((prevQuantities) => {
      const newQuantity = (prevQuantities[itemid] || 0) + change;
      return {
        ...prevQuantities,
        [itemid]: Math.max(newQuantity, 0), // Ensure quantity doesn't go below 0
      };
    });
  };

  // console.log("items ", quantities);

  const columns1 = useMemo(() => [
    {
      field: "itemid",
      headerName: "ID",
      flex: 1,
      maxWidth: 50,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.itemSequenceId}</Text>,
    },
    {
      field: "itemname",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "8px",
            objectFit: "cover",
          }}
          img={row.item_image_url}
          sx={{
            height: 36,
          }}
          title={row.itemname}
          starCount={4}
        />
      ),
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => <Text bold>₹ {row?.price}</Text>,
    },
    {
      field: "quantity",
      headerName: "Qty",
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <button
            variant="outlined"
            style={{
              background: "white",
              border: "1.3px solid #4F46E5",
              borderRadius: "0.4rem",
              cursor: "pointer",
            }}
            onClick={() => handleQuantityChange(row?.itemid, -1)}
          >
            -
          </button>
          <Text style={{ margin: "0 10px" }}>
            {quantities[row?.itemid] || 0}
          </Text>
          <button
            variant="outlined"
            style={{
              background: "white",
              border: "1.3px solid #4F46E5",
              borderRadius: "0.4rem",
              cursor: "pointer",
            }}
            onClick={() => handleQuantityChange(row?.itemid, 1)}
          >
            +
          </button>
        </Box>
      ),
    },
  ]);

  const handleSeeOrder = async () => {
    setOrderOpen(false);
    setLoading1(true);
    try {
      const orderData = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}outlet/menu/getDetailsForItems`,
        {
          items: quantities,
          outletId: outletIdNew,
          isDelivery: orderType == "Delivery" ? true : false,
          isDineIn: orderType == "Dine In" ? true : false,
          isPickUp: orderType == "Take Away" ? true : false,
        }
      );

      // console.log("orderData ", orderData);

      if (orderData?.data?.success) {
        setLoading1(false);
        setOrderDetail(orderData?.data?.data[0]);
        setOpenDetail(true);
      }
    } catch (error) {
      console.log("Error while fetch order data");
    }
  };

  useEffect(() => {
    // console.log("orderType changed:", orderType);
    handleSeeOrder();
  }, [orderType]);

  const handlePlaceOrder = async () => {
    setLoading(true);
    try {
      // console.log("order ", orderDetail);

      const order = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}payment/customer/getTransactionIdForCOD`,
        {
          itemTotalPrice: orderDetail?.base_price,
          productinfo: "items",
          firstname: "Restaurant",
          phone: "9638819704",
          email: "gajerajaymin5@gmail.com",
          customerAuthUID: "f35229d5-3ecc-41de-80d2-0e9615a24b88",
          outletId: "006fe90c-1234-40da-a4f3-f386164236e7",
          isDelivery: orderType == "Delivery" ? true : false,
          isDineIn: orderType == "Dine In" ? true : false,
          isPickUp: orderType == "Take Away" ? true : false,
        }
      );

      // console.log("orderData ", order);

      if (order?.data?.success) {
        const orderDaata = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/createOrder`,
          {
            additional_Instruction: "",
            address: "",
            basePrice: order?.data?.response?.foodBasePrice,
            customerAuthUID: order?.data?.response?.customerAuthUID,
            outletId: outletIdNew,
            paymentId: "",
            isCashOrder: true,
            isDelivery: orderType == "Delivery" ? true : false,
            isDineIn: orderType == "Dine In" ? true : false,
            isPickUp: orderType == "Take Away" ? true : false,
            isScheduleNow: true,
            pickupTime: {
              orderDate: new Date()?.toISOString().split("T")[0],
              time: new Date()?.toTimeString().split(" ")[0],
            },
            items: orderDetail?.menu_item,
            restaurantId: null,
            totalPrice: order?.data?.response?.amount,
            txnid: order?.data?.response?.txnid,
          }
        );

        // console.log("place order ", orderDaata);

        if (orderDaata?.data?.success) {
          const orderrr = await axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/sendOrderToHistory`,
            {
              orderId: orderDaata?.data?.data?.orderid,
            }
          );

          // console.log("send roder to history ", orderrr);

          if (orderrr?.data?.success) {
            setOrderOpen(false);
            setOpenDetail(false);
            setQuantities({});

            // navigate("/orders/#history");
            window?.location?.reload();
            toast.success("Order Placed Successfully");
          }
        }
      }
    } catch (error) {
      console.log("Error while create order");
    } finally {
      setLoading(false);
    }
  };

  const { user } = useSelector((state) => state.auth);
  const isAuthId = !!user?.outletStaffData?.outletStaffAuthUId;

  const today = new Date();
  const dispatch = useDispatch();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      id: 1,
      name: "Total Revenue",
      amount: 777,
      growth: 50,
    },
    {
      id: 2,
      name: "Total Customers",
      amount: 999,
      growth: 50,
      noRupeeSymbol: true,
    },
    {
      id: 3,
      name: "Total Orders",
      amount: 999,
      growth: 50,

      noRupeeSymbol: true,
    },
    {
      id: 4,
      name: "Total Menus",
      amount: 999,
      growth: 50,
      noRupeeSymbol: true,
    },
  ]);
  const [summaryData, setSummaryData] = useState([
    {
      id: 1,
      name: "Current Orders",
      amount: 777,
      growth: 50,
    },
    {
      id: 2,
      name: "Upcoming Orders",
      amount: 777,
      growth: 50,
    },
    {
      id: 3,
      name: "Cancelled Order",
      amount: 777,
      growth: 50,
    },
    {
      id: 4,
      name: "Refunded order",
      amount: 777,
      growth: 50,
    },
  ]);
  const [topMenu, setTopMenu] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [orderPaymentMethod, setOrderPaymentMethod] = useState([]);
  const [orders, setOrders] = useState([]);
  const [view, setView] = useState("Yearly");
  const [filtervalue, setFilterValue] = useState("Yearly");

  // const checkIsMess = useSelector((state) => state.duplicateOutletId.checkIsMess);

  // if(checkIsMess){
  //   navigate('/mdashboard');
  // }else{
  //   navigate('/');
  // }

  const matches = useMediaQuery("(max-width: 1352px)");

  const [isTimeExtendedLoading, setIsTimeExtendedLoading] = useState(false);

  const { isLoading, isFetching } = useQuery(
    ["dashbaordData", view, outletIdNew],
    () =>
      outletIdNew &&
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + `order/order/dashboardData`,
        {
          target_date:
            view === "Last Year"
              ? `${year - 1}-${month}-${day}`
              : formattedDate,
          outlet_id: outletIdNew,
          analyticalType:
            view === "Last Year"
              ? "year"
              : view.toLocaleLowerCase().slice(0, -2),
          isAdmin: isAuthId ? false : true,
        }
      ),
    {
      onSuccess: (res) => {
        let dashboardData = res?.data?.data;
        // console.log("Dash Data for order summery", dashboardData);
        setData([
          {
            id: 1,
            name: "Total Revenue",
            amount: dashboardData?.total_revenue,
            growth: 50,
            tooltipText: "Total sales of your outlet through MealPe",
          },
          {
            id: 2,
            name: "Total Customers",
            amount: dashboardData?.total_customers,
            growth: 50,
            tooltipText:
              " Number of customers who have place order through Mealpe",
            noRupeeSymbol: true,
          },
          {
            id: 3,
            name: "Total Orders",
            amount: dashboardData?.total_orders,
            tooltipText:
              "Total orders received through the app over a certain period of time",
            growth: 50,
            noRupeeSymbol: true,
          },
          {
            id: 4,
            name: "Average Order Value",
            amount: dashboardData?.average_order_value,
            tooltipText: "Total Revenue/Total number of Orders",
            growth: 50,
            noRupeeSymbol: true,
          },
        ]);
        setSummaryData([
          {
            id: 1,
            name: "Current Orders",
            amount: dashboardData?.current_order,
            growth: 50,
          },
          {
            id: 2,
            name: "Upcoming Orders",
            amount: dashboardData?.upcoming_order,

            growth: 50,
          },
          {
            id: 3,
            name: "Pre Paid Orders",
            amount: dashboardData?.total_online_orders,
            growth: 50,
          },
          {
            id: 4,
            name: "Cash On Delivery Orders",
            amount: dashboardData?.total_cash_orders,
            growth: 50,
          },
          // {
          //   id: 3,
          //   name: "Cancelled Order",
          //   amount: dashboardData?.total_cancalled,
          //   growth: 50,
          // },
          // {
          //   id: 4,
          //   name: "Refunded order",
          //   amount: dashboardData?.total_refund,
          //   growth: 50,
          // },
        ]);
      },
    }
  );

  const { isLoading: isLoading1, isFetching: isFetching1 } = useQuery(
    ["revenue-chart", view, outletIdNew],
    () =>
      outletIdNew &&
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "order/order/customerDineinPickupDeliveryOutlet",
        {
          targetDate:
            view === "Last Year"
              ? `${year - 1}-${month}-${day}`
              : formattedDate,
          outletId: outletIdNew,
          analyticaltype:
            view === "Last Year"
              ? "year"
              : view.toLocaleLowerCase().slice(0, -2),
        }
      ),
    {
      onSuccess: (res) => {
        // console.log("Dashboard data for Chart ", res?.data?.data);
        const chartData = [
          {
            id: "Delivery",
            label: "Delivery",
            value: res?.data?.data?.[0]?.deliverycount,
            color: "#9E77ED",
          },
          {
            id: "Dine In",
            label: "Dine In",
            value: res?.data?.data?.[0]?.dineincount,
            color: "#B692F6",
          },
          {
            id: "Take Away",
            label: "Take Away",
            value: res?.data?.data?.[0]?.pickupcount,
            color: "#F4EBFF",
          },
        ];

        const chartData1 = [
          {
            id: "Pre Paid Orders",
            label: "Pre Paid",
            value: res?.data?.data?.[0]?.total_online_orders,
            color: "#9E77ED",
          },
          {
            id: "Cash On Delivery Orders",
            label: "COD",
            value: res?.data?.data?.[0]?.total_cash_orders,
            color: "#F4EBFF",
          },
        ];

        // Example usage:
        // const currentDate = new Date(); // Get the current date
        // const revenueData = [
        //   {
        //     label: "2023-08-24",
        //     total_price: 19.609299999999998,
        //   },
        //   {
        //     label: "2023-08-25",
        //     total_price: 3206.0661,
        //   },
        // ];

        // const updatedDateDataArray = updateDateDataWithRevenueMonthly(
        //   currentDate,
        //   revenueData
        // );

        setRevenue(chartData);
        setOrderPaymentMethod(chartData1);
      },
    }
  );

  const { isLoading: isLoading2, isFetching: isFetching2 } = useQuery(
    ["orders-chart", view, outletIdNew],
    () =>
      outletIdNew &&
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "order/order/peakTimeAnalyticsOutlet",
        {
          outletId: outletIdNew,
          analyticalType:
            view === "Last Year"
              ? "year"
              : view.toLocaleLowerCase().slice(0, -2),
          target_date:
            view === "Last Year"
              ? `${year - 1}-${month}-${day}`
              : formattedDate,
        }
      ),
    {
      onSuccess: (res) => {
        const chartData = [];

        const timeLabels = [
          "12 AM",
          "01 AM",
          "02 AM",
          "03 AM",
          "04 AM",
          "05 AM",
          "06 AM",
          "07 AM",
          "08 AM",
          "09 AM",
          "10 AM",
          "11 AM",
          "12 PM",
          "01 PM",
          "02 PM",
          "03 PM",
          "04 PM",
          "05 PM",
          "06 PM",
          "07 PM",
          "08 PM",
          "09 PM",
          "10 PM",
          "11 PM",
        ];

        for (let i = 0; i < 24; i++) {
          const matchedData = res?.data?.data?.find(
            (hourData) => hourData.hour === i + 1
          );

          let dineIn = 0;
          let pickUp = 0;
          let delivery = 0;

          if (matchedData) {
            dineIn = matchedData?.dine_in_count;
            pickUp = matchedData?.pick_up_count;
            delivery = matchedData?.delivery_count;
          }

          chartData.push({
            country: timeLabels[i],
            "Dine In": dineIn,
            "Take Away": pickUp,
            Delivery: delivery,
          });
        }

        // // Example usage:
        // const currentDate = new Date(); // Get the current date
        // const revenueData = [
        //   {
        //     label: "2023-08-24",
        //     total_price: 19.609299999999998,
        //   },
        //   {
        //     label: "2023-08-25",
        //     total_price: 3206.0661,
        //   },
        // ];

        // const updatedDateDataArray = updateDateDataWithRevenueMonthly(
        //   currentDate,
        //   revenueData
        // );

        setOrders(chartData);
      },
    }
  );
  const { isLoading: isLoading3, isFetching: isFetching3 } = useQuery(
    ["top-menu", outletIdNew, filtervalue],
    () =>
      outletIdNew &&
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + `order/order/topThreeMenuItem`,
        {
          analyticaltype:
            filtervalue == "Daily"
              ? "day"
              : filtervalue == "Last Year"
              ? "year"
              : filtervalue.toLocaleLowerCase().slice(0, -2),
          outletId: outletIdNew,
          targetDate:
            filtervalue === "Last Year"
              ? `${year - 1}-${month}-${day}`
              : formattedDate,
        }
      ),
    {
      onSuccess: (res) => {
        setTopMenu(res?.data?.data);
      },
    }
  );

  // async function onIsTimeExtendedChange(_value) {
  //   if (outletDetails?.outletId) {
  //     const postData = {
  //       isTimeExtended: _value
  //     }
  //     const updatePetPoojaConfigsResponse = await updatePetPoojaConfigs(postData, outletDetails?.outletId);

  //     if (updatePetPoojaConfigsResponse?.data?.success) {
  //       dispatch(authLogin({ ...outletDetails, ...postData }));
  //     }
  //   }
  // }

  const onIsTimeExtendedChange = (values) => {
    setIsTimeExtendedLoading(true);
    let postbody = {
      isOutletOpen: values,
      outletId: outletId,
    };
    updateOutletOpenStatus(postbody)
      .then((res) => {
        dispatch(authLogin({ ...outletDetails, isOutletOpen: values }));
        toast.success(
          // res?.data?.data?.[0]?.isOutletOpen
          //   ? "Time extended Successfully"
          //   : "The time extension has concluded"

          values ? "Outlet Open Successfully" : "Outlet Close Successfully"
        );
        setIsTimeExtendedLoading(false);
      })
      .catch((e) => {
        setIsTimeExtendedLoading(false);
        toast.error("something went wrong please try again!");
      });
  };

  // useEffect(() => {
  // }, [outletDetails])
  const columns = useMemo(() => [
    {
      field: "item",
      headerName: "Item",
      flex: 1,
      // minWidth: 150,
      // maxWidth: 300,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "8px",
            objectFit: "cover",
          }}
          img={row.item_image_url}
          sx={{
            height: 36,
          }}
          title={row?.itemname}
          titleSx={{ marginTop: "6px" }}
        />
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      // minWidth: 100,
      maxWidth: 100,
      sortable: false,
      alignItems: "center",
      justifyContent: "center",
      renderCell: ({ row }) => <Text bold>{row?.total_count}</Text>,
    },
  ]);

  console.log("Order Details ", orderDetail);

  // const handlePrint = (orderDetail) => {
  //   var content = document.createElement("div");
  //   content.innerHTML = getHPrintHTML(orderDetail);

  //   var pri = document.getElementById("ifmcontentstoprint")?.contentWindow;

  //   pri?.document?.open();
  //   pri?.document?.write(content.innerHTML);
  //   pri?.document?.write(`
  //   <style type="text/css">
  //     @media print {
  //       @page {
  //           margin-top: 0;
  //           margin-bottom: 0;
  //       }
  //       body {
  //           padding-top: 10px;
  //           padding-bottom: 10px;
  //           margin: 0 !important;
  //       }
  //     }
  //   </style>
  //   `);

  //   pri?.document?.close();
  //   pri?.focus();
  //   pri?.print();
  // };
  // function getHPrintHTML(orderDetail) {
  //   // console.log("order ", order);
  //   const itemsHTML = orderDetail?.menu_item?.map(
  //     (item) =>
  //       `<div>
  //         <div style="float:left; margin-bottom:1px;">
  //         ${
  //           orderDetail?.additional_instruction
  //             ? "[note] : " + orderDetail?.additional_instruction
  //             : ""
  //         }
  //         </div><br/>
  //         <div style="float:left; font-weight: bold;">${item?.itemname} ${
  //         item?.variation?.variation_name
  //           ? ` ${item?.variation?.variation_name}`
  //           : ""
  //       } x ${item?.quantity}
  //         </div>
  //         <div style="float:right; font-weight: bold;">
  //           ₹${
  //             item?.variation?.variation_price
  //               ? item?.variation?.variation_price
  //               : item?.calculated_price
  //           }
  //         </div>
  //         <div style="clear:both"></div>
  //          ${
  //            item?.addons?.length
  //              ? item?.addons
  //                  ?.map(
  //                    (addon) =>
  //                      `<div>
  //                   <div style="float:left;">${addon?.name}</div>
  //                   <div style="float:right;">₹${addon?.price}</div>
  //                   <div style="clear:both"></div>
  //                 </div>`
  //                  )
  //                  .join("")
  //              : ""
  //          }
  //       </div>`
  //   );

  //   const _html = `
  //     <div style="border:1px solid lightgray; height:auto; width: 65mm; margin:auto; padding:5px">
  //       <div style="text-align:center; font-size:18px; font-weight:600">${
  //         user?.outletName
  //       }</div>
  //       <div style="text-align:center">${user?.GSTIN ? "GSTIN: " + user?.GSTIN : ""}</div>
  //       <div style="text-align:center">${user?.FSSAI_number ? "FSSAI No:" + user?.FSSAI_number : ""}</div>
  //       <div style="text-align:center">${user?.address}</div>
  //       <div style="text-align:center">Mobile: + 91 ${user?.mobile}</div>
  //       <div style="text-align:center">Email: ${user?.email}</div>
  //       <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>

  //       <div>
  //         <div style="float:left">${orderDetail?.order_schedule_date}</div>
  //         <div style="float:right">${convertTo12HourFormat(
  //           orderDetail?.order_schedule_time,
  //           orderDetail?.preparation_time,
  //           orderDetail?.schedule_now
  //         )}</div>
  //         <div style="clear:both"></div>
  //       </div>
  //       <div style="clear: both;border-bottom: 1px dotted black; margin-block:5px"></div>

  //       <div>Order ID: #${orderDetail?.order_sequence_id}</div>

  //       <div style="margin-top:30px;">
  //         ${itemsHTML.join("")}
  //         <div style="clear:both"></div>
  //       </div>

  //       <div style="clear:both; border-bottom: 1px dotted black; margin-block:5px"></div>

  //       <div>
  //         <div>
  //           <div style="float:left">Subtotal + Taxes:</div>
  //           <div style="float:right">₹ ${orderDetail?.base_price}</div>
  //         </div>
  //         ${
  //           orderDetail?.is_delivery || orderDetail?.is_pick_up
  //             ? `<div style="clear:both">
  //                  <div style="float:left">Packaging Charges:</div>
  //                  <div style="float:right">₹ ${
  //                    orderDetail?.packaging_charge || 0
  //                  }</div>
  //                </div>`
  //             : ""
  //         }
  //         ${
  //           orderDetail?.is_delivery && !orderDetail?.is_pick_up
  //             ? `<div style="clear: both">
  //                  <div style="float:left">Delivery Charge:</div>
  //                  <div style="float:right">₹ ${
  //                    orderDetail?.deliverycharge || 0
  //                  }</div>
  //                </div>`
  //             : ""
  //         }
  //         <div style="display: ${
  //           orderDetail?.isgstapplied ? `block` : `none`
  //         }; clear:both">
  //           <div style="float:left">Taxes:</div>
  //           <div style="float:right">₹ ${orderDetail?.food_gst || 0}</div>
  //         </div>
  //         <div style="clear: both">
  //           <div style="float:left">Convenience Fee:</div>
  //           <div style="float:right">₹ ${
  //             orderDetail?.convenience_total_amount?.toFixed(2) || 0
  //           }</div>
  //         </div>
  //         <div style="clear:both"></div>
  //       </div>

  //       <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>

  //       <div>
  //         <div style="float:left">Total:</div>
  //         <div style="float:right">₹ ${orderDetail?.amount?.toFixed(2)}</div>
  //         <div style="clear:both"></div>
  //       </div>

  //       <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
  //     </div>
  //   `;

  // console.log("Order Details ", orderDetail);

  const handlePrint = (orderDetail) => {
    var content = document.createElement("div");
    content.innerHTML = getHPrintHTML(orderDetail);

    var pri = document.getElementById("ifmcontentstoprint")?.contentWindow;

    pri?.document?.open();
    pri?.document?.write(content.innerHTML);
    pri?.document?.write(`
    <style type="text/css">
      @media print {
        @page {
            margin-top: 0;
            margin-bottom: 0;
        }
        body {
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0 !important;
        }
      }
    </style>
    `);

    pri?.document?.close();
    pri?.focus();
    pri?.print();
  };
  function getHPrintHTML(orderDetail) {
    // console.log("order ", order);
    const itemsHTML = orderDetail?.menu_item?.map(
      (item) =>
        `<div>
          <div style="float:left; margin-bottom:1px;">
          ${
            orderDetail?.additional_instruction
              ? "[note] : " + orderDetail?.additional_instruction
              : ""
          }
          </div><br/>
          <div style="float:left; font-weight: bold;">${item?.itemname} ${
          item?.variation?.variation_name
            ? ` ${item?.variation?.variation_name}`
            : ""
        } x ${item?.quantity}  
          </div>
          <div style="float:right; font-weight: bold;">
            ₹${
              item?.variation?.variation_price
                ? item?.variation?.variation_price
                : item?.calculated_price
            }
          </div>
          <div style="clear:both"></div>
           ${
             item?.addons?.length
               ? item?.addons
                   ?.map(
                     (addon) =>
                       `<div>
                    <div style="float:left;">${addon?.name}</div>
                    <div style="float:right;">₹${addon?.price}</div>
                    <div style="clear:both"></div>
                  </div>`
                   )
                   .join("")
               : ""
           }
        </div>`
    );

    const _html = `
      <div style="border:1px solid lightgray; height:auto; width: 65mm; margin:auto; padding:5px">
        <div style="text-align:center; font-size:18px; font-weight:600">${
          user?.outletName
        }</div>
        <div style="text-align:center">${
          user?.GSTIN ? "GSTIN: " + user?.GSTIN : ""
        }</div>
        <div style="text-align:center">${
          user?.FSSAI_number ? "FSSAI No:" + user?.FSSAI_number : ""
        }</div>
        <div style="text-align:center">${user?.address}</div>
        <div style="text-align:center">Mobile: + 91 ${user?.mobile}</div>
        <div style="text-align:center">Email: ${user?.email}</div>
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
    
        <div>
          <div style="float:left">${orderDetail?.order_schedule_date}</div>
          <div style="float:right">${convertTo12HourFormat(
            orderDetail?.order_schedule_time,
            orderDetail?.preparation_time,
            orderDetail?.schedule_now
          )}</div>
          <div style="clear:both"></div>
        </div>
        <div style="clear: both;border-bottom: 1px dotted black; margin-block:5px"></div>
    
        <div>Order ID: #${orderDetail?.order_sequence_id}</div>
        
        <div style="margin-top:30px;">
          ${itemsHTML.join("")}
          <div style="clear:both"></div>
        </div>
         
        <div style="clear:both; border-bottom: 1px dotted black; margin-block:5px"></div>
        
        <div>
          <div>
            <div style="float:left">Sub Total:</div>
            <div style="float:right">₹ ${orderDetail?.base_price}</div>
          </div>
          ${
            orderDetail?.is_delivery || orderDetail?.is_pick_up
              ? `<div style="clear:both">
                   <div style="float:left">Packaging Charges:</div>
                   <div style="float:right">₹ ${
                     orderDetail?.packaging_charge || 0
                   }</div>
                 </div>`
              : ""
          }
          ${
            orderDetail?.is_delivery && !orderDetail?.is_pick_up
              ? `<div style="clear: both">
                   <div style="float:left">Delivery Charge:</div>
                   <div style="float:right">₹ ${
                     orderDetail?.deliverycharge || 0
                   }</div>
                 </div>`
              : ""
          }
          <div style="display: ${
            orderDetail?.isgstapplied ? `block` : `none`
          }; clear:both">
            <div style="float:left">Taxes:</div>
            <div style="float:right">₹ ${orderDetail?.foodGST || 0}</div>
          </div>
          <div style="clear: both">
            <div style="float:left">Convenience Fee:</div>
            <div style="float:right">₹ ${
              orderDetail?.convenienceTotalAmount?.toFixed(2) || 0
            }</div>
          </div>
          <div style="clear:both"></div>
        </div>
        
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
        
        <div>
          <div style="float:left">Total:</div>
          <div style="float:right">₹ ${orderDetail?.totalPriceForCustomer?.toFixed(
            2
          )}</div>
          <div style="clear:both"></div>
        </div>
        
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
      </div>
    `;

    return _html;
  }

  function convertTo12HourFormat(time, preparationTime, addTime) {
    // Remove seconds
    time = time?.split(":")?.slice(0, 2)?.join(":");

    // Convert time to total minutes
    let [hours, minutes] = time?.split(":")?.map(Number);
    let totalMinutes = hours * 60 + minutes;

    // Adjust time based on the boolean value
    if (addTime) {
      totalMinutes += preparationTime + 15;
    }

    // Calculate the new time
    let newHours = Math.floor(totalMinutes / 60) % 24;
    let newMinutes = totalMinutes % 60;

    // Determine AM or PM
    let period = newHours >= 12 ? "PM" : "AM";

    // Adjust hours for 12-hour format
    if (newHours > 12) {
      newHours -= 12;
    } else if (newHours === 0) {
      newHours = 12;
    }

    // Format the time in 12-hour format
    let time12Hour = `${newHours}:${
      newMinutes < 10 ? "0" : ""
    }${newMinutes} ${period}`;

    return time12Hour;
  }

  const renderFilter = () => {
    return (
      <StackRow gap={"0.7rem"} center>
        {["Last Year", "Yearly", "Monthly", "Weekly"].map((v, index) => (
          <CustomButton
            key={index}
            variant={v == view ? "contained" : "outlined"}
            sx={{
              filter: v != view ? "grayscale(1)" : "none",
              width: "100%",
            }}
            textContent={v}
            onClick={() => {
              setView(v);
            }}
          />
        ))}
      </StackRow>
    );
  };

  const renderTakeOrder = () => {
    return (
      <CustomButton onClick={() => setOrderOpen(true)}>
        <AddCircleOutlineIcon style={{ marginRight: "0.5rem" }} /> Take Order
      </CustomButton>
    );
  };

  const renderCustomSwitch = () => {
    return (
      <CustomSwitch
        value={Boolean(outletDetails?.isOutletOpen)}
        label={"Accepting orders"}
        isInternalSetState={false}
        onChange={(_value) => {
          // console.log("values ", _value);
          onIsTimeExtendedChange(_value);
        }}
      />
    );
  };

  return (
    <>
      <>
        {outletDetails?.publishProcessingStep >= 3 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box
              sx={{
                height: "20px",
              }}
            >
              {(isFetching || isFetching2 || isFetching1 || isFetching3) && (
                <LinearProgress />
              )}
              {isTimeExtendedLoading && <LinearProgress />}
            </Box>
            {!!user?.messId && isSmallScreen && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #5468FF",
                  borderRadius: "8px",
                  marginBottom: "1rem",
                  marginTop: "-3rem",
                }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  sx={{
                    minHeight: "28px", // Adjust the height of the TabBar
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                    "& .Mui-selected": {
                      color: "#5468FF", // Custom selected tab color
                      backgroundColor: "#E5EDFF", // Background for the selected tab
                      // borderRadius: "8px",
                    },
                  }}
                  TabIndicatorProps={{
                    style: { display: "none" }, // Hide the indicator
                  }}
                >
                  <Tab
                    label={
                      <Box
                        sx={{ position: "relative", display: "inline-block" }}
                      >
                        <Badge
                          badgeContent={pendingOrders?.length}
                          color="primary"
                          sx={{
                            position: "absolute",
                            // top: "-8px",
                            left: "-12px",
                            zIndex: 1, // Ensures the badge stays on top
                          }}
                        />
                        Outlet
                      </Box>
                    }
                    sx={{
                      textTransform: "none",
                      color: "#5468FF",
                      fontWeight: "bold",
                      minHeight: "28px", // Adjust the height of individual tabs
                      padding: "0 16px", // Reduce padding for a smaller tab size
                      borderRadius: "8px 0px 0px 8px",
                      // borderRight: '1px solid #5468FF',
                    }}
                  />
                  <Tab
                    label="Mess"
                    sx={{
                      textTransform: "none",
                      color: "#5468FF",
                      fontWeight: "bold",
                      minHeight: "28px", // Adjust the height of individual tabs
                      padding: "0 16px", // Reduce padding for a smaller tab size
                      borderRadius: "0px 8px 8px 0px",
                      borderLeft: "1px solid #5468FF",
                    }}
                  />
                </Tabs>
              </Box>
            )}
            <StackRow between center>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  marginBottom: "2rem",
                  marginTop: isSmallScreen
                    ? !!user?.messId
                      ? ""
                      : "-5rem"
                    : "",
                }}
              >
                <div>
                  <Headline1>Dashboard</Headline1>
                  <Text mb={"1.5rem"}>Welcome to MealPe</Text>{" "}
                </div>
                {isSmallScreen ? null : renderTakeOrder()}
              </div>
              {isSmallScreen ? null : (
                <StackRow gap="0.7rem" center>
                  {renderCustomSwitch()}
                  {renderFilter()}
                </StackRow>
              )}
            </StackRow>

            {isSmallScreen ? (
              <div style={{ marginTop: -20 }}>
                <StackRow between center>
                  {renderTakeOrder()}
                  {renderCustomSwitch()}
                </StackRow>
                <div style={{ marginTop: 20, marginBottom: 25 }}>
                  {renderFilter()}
                </div>
              </div>
            ) : null}

            {isLoading && isLoading1 && isLoading2 && isLoading3 ? (
              <Grid
                container
                spacing={"1.7rem"}
                rowSpacing={"2.8rem"}
                columnSpacing={"1.7rem"}
              >
                {" "}
                {new Array(4).fill(0).map((order, index) => {
                  return (
                    <Grid item xs={3} key={index}>
                      <PaperBox padding={"2.28rem"}>
                        <Skeleton
                          height={"1.5rem"}
                          width={"60%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 2,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"2.5rem"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                          }}
                          animation="wave"
                        />
                      </PaperBox>
                    </Grid>
                  );
                })}
                <Grid item xs={12} md={12}>
                  <PaperBox
                    sx={{
                      mb: 4,
                    }}
                  >
                    <Box sx={{}}>
                      <Skeleton
                        height={"1.5rem"}
                        width={"10%"}
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 2,
                        }}
                        animation="wave"
                      />
                      {/* <Skeleton
                        height={"1.5rem"}
                        width={"100%"}
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 2,
                        }}
                        animation="wave"
                      /> */}
                      <Skeleton
                        height={"16.7rem"}
                        width={"100%"}
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                        }}
                        animation="wave"
                      />
                    </Box>
                  </PaperBox>
                  {/* <PaperBox>
                    <Skeleton
                      height={"1.5rem"}
                      width={"60%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 2,
                      }}
                      animation="wave"
                    />
                    <Skeleton
                      height={"1.5rem"}
                      width={"30%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 2,
                      }}
                      animation="wave"
                    />
                    <Skeleton
                      height={"16.7rem"}
                      width={"100%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                      }}
                      animation="wave"
                    />
                  </PaperBox> */}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item md={3}>
                      <PaperBox
                        sx={{
                          mb: 4,
                          mr: 4,
                        }}
                      >
                        <StackRow between>
                          <Skeleton
                            height={"35px"}
                            width={"50%"}
                            sx={{
                              transform: "scale(1)",
                              transformOrigin: "0",
                              mb: 4,
                            }}
                            animation="wave"
                          />
                          <Skeleton
                            height={"35px"}
                            width={"30%"}
                            sx={{
                              transform: "scale(1)",
                              transformOrigin: "0",
                              mb: 1,
                            }}
                            animation="wave"
                          />
                        </StackRow>
                        <Skeleton
                          height={"50px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            margin: "auto",
                            mb: 3,
                          }}
                          animation="wave"
                        />
                        {/* <Divider
                      sx={{
                        margin: "18px 0",
                      }}
                    /> */}
                        <Skeleton
                          height={"40px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Divider
                          sx={{
                            margin: "18px 0",
                          }}
                        />
                        <Skeleton
                          height={"40px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Divider
                          sx={{
                            margin: "18px 0",
                          }}
                        />
                        <Skeleton
                          height={"40px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Divider
                          sx={{
                            margin: "18px 0",
                          }}
                        />
                        <Skeleton
                          height={"40px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Divider
                          sx={{
                            margin: "18px 0",
                          }}
                        />
                        <Skeleton
                          height={"40px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Divider
                          sx={{
                            margin: "18px 0",
                          }}
                        />
                      </PaperBox>
                    </Grid>
                    <Grid item md={9}>
                      <Grid container>
                        <Grid item md={12}>
                          <PaperBox>
                            <Skeleton
                              height={"40px"}
                              width={"20%"}
                              sx={{
                                transform: "scale(1)",
                                transformOrigin: "0",
                                mb: 4,
                              }}
                              animation="wave"
                            />
                            <StackRow between>
                              <Skeleton
                                height={"8rem"}
                                width={"13rem"}
                                // variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                  mr: 2,
                                  flex: 1,
                                }}
                                animation="wave"
                              />
                              <Skeleton
                                height={"8rem"}
                                width={"13rem"}
                                // variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                  mr: 2,
                                  flex: 1,
                                }}
                                animation="wave"
                              />
                              <Skeleton
                                height={"8rem"}
                                width={"13rem"}
                                // variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                  mr: 2,
                                  flex: 1,
                                }}
                                animation="wave"
                              />
                              <Skeleton
                                height={"8rem"}
                                width={"13rem"}
                                // variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                  mr: 2,
                                  flex: 1,
                                }}
                                animation="wave"
                              />
                              {/* <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack> */}
                            </StackRow>
                            {/* <StackRow between>
                      <Skeleton
                        height={"4.2rem"}
                        width={"4.2rem"}
                        variant="circular"
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 4,
                        }}
                        animation="wave"
                      />
                      <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack>
                    </StackRow> */}
                            {/* <StackRow between>
                      <Skeleton
                        height={"4.2rem"}
                        width={"4.2rem"}
                        variant="circular"
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 4,
                        }}
                        animation="wave"
                      />
                      <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack>
                    </StackRow> */}

                            {/* <Skeleton
                      height={"50px"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 4,
                      }}
                      animation="wave"
                    /> */}
                          </PaperBox>
                        </Grid>
                        <Grid item md={6}>
                          <PaperBox>
                            <Skeleton
                              height={"40px"}
                              width={"40%"}
                              sx={{
                                transform: "scale(1)",
                                transformOrigin: "0",
                                mb: 4,
                              }}
                              animation="wave"
                            />
                            <StackRow between>
                              <Skeleton
                                height={"15rem"}
                                width={"15rem"}
                                variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                }}
                                animation="wave"
                              />
                            </StackRow>
                          </PaperBox>
                        </Grid>
                        <Grid item md={6}>
                          <PaperBox>
                            <Skeleton
                              height={"40px"}
                              width={"40%"}
                              sx={{
                                transform: "scale(1)",
                                transformOrigin: "0",
                                mb: 4,
                              }}
                              animation="wave"
                            />
                            <StackRow between>
                              <Skeleton
                                height={"15rem"}
                                width={"15rem"}
                                variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                }}
                                animation="wave"
                              />
                            </StackRow>
                          </PaperBox>
                        </Grid>
                      </Grid>
                      {/* <PaperBox>
                    <Skeleton
                      height={"40px"}
                      width={"60%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 4,
                      }}
                      animation="wave"
                    />
                    <StackRow between>
                      <Skeleton
                        height={"4.2rem"}
                        width={"4.2rem"}
                        variant="circular"
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 4,
                        }}
                        animation="wave"
                      />
                      <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack>
                    </StackRow>
                    <StackRow between>
                      <Skeleton
                        height={"4.2rem"}
                        width={"4.2rem"}
                        variant="circular"
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 4,
                        }}
                        animation="wave"
                      />
                      <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack>
                    </StackRow>
                    <StackRow between>
                      <Skeleton
                        height={"4.2rem"}
                        width={"4.2rem"}
                        variant="circular"
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 4,
                        }}
                        animation="wave"
                      />
                      <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack>
                    </StackRow>

                    <Skeleton
                      height={"50px"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 4,
                      }}
                      animation="wave"
                    />
                  </PaperBox> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                spacing={"1.7rem"}
                rowSpacing={"2.8rem"}
                columnSpacing={"1.7rem"}
              >
                {data.map((card, index) => (
                  <Grid item xs={12} md={3} key={index}>
                    <DashboardCard sx={{ flex: 1 }} {...card} />
                  </Grid>
                ))}

                <Grid item xs={12} md={12}>
                  {/* <PaperBox
                    padding={"2.2rem"}
                    sx={{
                      mb: 4,
                    }}
                  >
                    <Box sx={{ height: "25rem" }}>
                      <Headline4>Revenue</Headline4>
                      <Text>View and update your store details</Text>
                      <LineChart data={revenue} />
                    </Box>
                  </PaperBox> */}
                  <PaperBox padding={"2.2rem"}>
                    <Box sx={{ height: "25rem" }}>
                      <Headline4>Peak Time</Headline4>
                      {/* <Text>View and update your store details</Text> */}
                      <BarChart2 data={orders} />
                    </Box>
                  </PaperBox>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3.6}>
                      <PaperBox
                        sx={{
                          height: "39.3rem",
                          // padding: "1.14286rem",
                          // display: "flex",
                          // flexDirection: "column",
                          // gap: "1.47rem",
                        }}
                      >
                        <Stack gap={"1.43rem"}>
                          <StackRow center between>
                            <Headline3>Most Ordered</Headline3>
                            <StatusFilter
                              // label="Filter"
                              defaultValue={filtervalue}
                              menuList={[
                                { id: null, label: "Daily" },
                                { id: 1, label: "Weekly" },
                                { id: 2, label: "Monthly" },
                                { id: 3, label: "Yearly" },
                                { id: 4, label: "Last Year" },
                              ]}
                              onClick={(label) => setFilterValue(label)}
                            />
                          </StackRow>
                          {/* <Divider
                      sx={{
                        margin: "18px 0",
                      }}
                    /> */}
                          {/* {topMenu.length > 0 ? (
                      topMenu?.map((item) => {
                        return (
                          <ItemCard
                            imgSx={{
                              borderRadius: "50%",
                              width: "61.068px",
                              height: "55.415px",
                              objectFit: "cover",
                            }}
                            labelSx={{
                              fontSize: 14,
                              fontWeight: "500",
                            }}
                            sx={{
                              height: 40,
                              marginBottom: 3,
                            }}
                            title={item?.itemname}
                            subTitle={`${item?.total_count} dishes ordered`}
                            img={item?.item_image_url || FoodImg}
                          />
                        );
                      })
                    ) : (
                      <Text bold sx={{ marginLeft: "100px" }}>
                        No items yet
                      </Text>
                    )} */}
                          {topMenu ? (
                            <CustomDataGrid
                              hideFooter
                              rowId={"itemid"}
                              height={matches ? "28rem" : "30rem"}
                              name="top-items"
                              columns={columns}
                              dummyRows={topMenu}
                              loading={isLoading3 || isFetching3}
                              noActions
                              labelName={"Most Ordered"}
                              // sx={{'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' }}}
                              autosizeOptions={{
                                columns: ["name", "status", "createdBy"],
                                includeOutliers: true,
                                includeHeaders: false,
                              }}
                              noDataScreen
                            />
                          ) : (
                            <Text>No data found.</Text>
                          )}
                        </Stack>
                      </PaperBox>
                    </Grid>
                    <Grid item xs={12} md={8.4}>
                      <Stack gap={2}>
                        <PaperBox
                          padding={"2.2rem"}
                          sx={{
                            // width: "47.71429rem",
                            display: "flex",
                            // width: '100%',
                            padding: "1.14286rem",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "1rem",
                            "& .MuiBox-root": {
                              padding: "0",
                              width: "100%",
                            },
                          }}
                        >
                          <StackRow center>
                            <Headline4 sx={{ marginBottom: "2rem" }}>
                              Order Summary
                            </Headline4>
                            {/* <CustomButton
                        sx={{
                          width: "100px",
                          position: "relative",
                        }}
                        variant="outlined"
                        textContent={"View All"}
                        onClick={() => navigate("/orders")}

                      /> */}
                          </StackRow>
                          <Stack
                            direction={isSmallScreen ? "column" : "row"} // Stack vertically on small screens
                            gap={isSmallScreen ? "1rem" : "1.14rem"} // Adjust gap for small screens
                          >
                            {summaryData.map((order, index) => (
                              <PaperBox
                                // p={"1.7rem"}
                                key={index}
                                sx={{
                                  display: "flex",
                                  padding: isSmallScreen
                                    ? "1rem"
                                    : "1.14286rem 1.71429rem", // Adjust padding for small screens
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  gap: "0.28571rem",
                                  flex: "1 0 0",
                                  // Optionally adjust flex-basis for medium screens
                                  flexBasis: isMediumScreen ? "45%" : "0%", // Change basis for medium screens
                                  "& .MuiBox-root": {
                                    padding: "0",
                                  },
                                }}
                              >
                                <Headline3>{order?.amount || 0}</Headline3>
                                <Text
                                  sx={{
                                    color: "var(--gray-500, #64748B)",
                                    fontFamily: "Inter",
                                    fontSize: isSmallScreen ? "0.9rem" : "1rem", // Adjust font size for small screens
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "1.42857rem",
                                    letterSpacing: "-0.01rem",
                                    textWrap: "nowrap",
                                  }}
                                >
                                  {order?.name}
                                </Text>
                              </PaperBox>
                            ))}
                          </Stack>
                        </PaperBox>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: isSmallScreen ? "column" : "row", // Stack vertically on small screens
                            gap: isSmallScreen ? "1rem" : "1rem", // Keep the same gap for simplicity, adjust if needed
                          }}
                        >
                          <PaperBox
                            sx={{
                              height: "25rem", // Adjust height for small screens
                              width: isSmallScreen ? "100%" : "31.64rem", // Full width on small screens
                              maxWidth: isSmallScreen ? "100%" : "31.64rem",
                            }}
                          >
                            <Box sx={{ height: "25rem", width: "27.64rem" }}>
                              <Headline4>Order Type</Headline4>
                              <PieChart data={revenue} />
                            </Box>
                          </PaperBox>
                          <PaperBox
                            sx={{
                              height: "25rem", // Adjust height for small screens
                              width: isSmallScreen ? "100%" : "31.64rem", // Full width on small screens
                              maxWidth: isSmallScreen ? "100%" : "31.64rem",
                            }}
                          >
                            <Box sx={{ height: "25rem", width: "27.64rem" }}>
                              <Headline4>Order Payment Type</Headline4>
                              <PieChart data={orderPaymentMethod} />
                            </Box>
                          </PaperBox>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {orderOpen && (
              <CustomDialog dontClose open={orderOpen} sx={{ width: "80rem" }}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <PageHeader secondary title={`Order Items`} />
                  <Close
                    style={{ cursor: "pointer", fontSize: "2rem" }}
                    onClick={() => setOrderOpen(false)}
                  />
                </Box>

                {/* <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          > */}

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <CustomSearchTextField
                    style={{ marginBottom: "1rem" }}
                    placeholder="Search"
                    value={search1}
                    onChange={(e) => setSearch1(e.target.value)}
                  />
                  {Object.values(quantities).some(
                    (quantity) => quantity > 0
                  ) && (
                    <Button
                      style={{
                        fontSize: "1.1rem",
                        backgroundColor: "#574eed",
                        color: "white",
                        marginBottom: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                      sx={{ textTransform: "none" }}
                      onClick={handleSeeOrder}
                    >
                      <LocalMallOutlinedIcon
                        style={{ fontSize: "1.9rem", marginRight: "0.5rem" }}
                      />{" "}
                      Check Out (
                      {Object.values(quantities).reduce(
                        (acc, res) => Number(acc) + Number(res),
                        0
                      )}
                      )
                    </Button>
                  )}
                </Box>
                <CustomDataGrid
                  rowId="itemid"
                  name="item-table"
                  height={"490px"}
                  columns={columns1}
                  url={itemGetUrl + `${outletIdNew}`}
                  labelName={"Items"}
                  noActions
                  filterFn={getFilterData1}
                />
                {/* </Box> */}
              </CustomDialog>
            )}

            {openDetail && (
              <CustomDialog dontClose open={openDetail} sx={{ width: "80rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1rem",
                      marginTop: "-3.5rem",
                      fontSize: "3rem",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBack
                      style={{ fontSize: "2rem" }}
                      onClick={() => setOrderOpen(true)}
                    />
                  </Box>

                  <PageHeader secondary title="Order Details" />

                  <Box
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      justifyContent: "flex-end",
                      marginTop: "-2.5rem",
                      marginRight: "1rem",
                    }}
                  >
                    <Text style={{ fontWeight: "500", fontSize: "1.3rem" }}>
                      Items
                    </Text>
                    <Text style={{ fontWeight: "500", fontSize: "1.3rem" }}>
                      (
                      {Object.values(quantities).reduce(
                        (acc, res) => Number(acc) + Number(res),
                        0
                      )}
                      )
                    </Text>
                  </Box>
                </Box>

                {loading1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px", // Adjust height as per your requirement
                    }}
                  >
                    <Text style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Calculating...
                    </Text>
                  </Box>
                ) : (
                  <>
                    <Box style={{ padding: "1.5rem" }}>
                      {orderDetail?.menu_item?.map((order) => (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <Text>
                            {order?.itemname} x {order?.quantity}
                          </Text>
                          <Text>₹{order?.price.toFixed(2)}</Text>
                        </Box>
                      ))}
                      <Divider />
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <Text>Sub Total:</Text>
                        <Text>₹{orderDetail?.base_price?.toFixed(2)}</Text>
                      </Box>
                      {orderDetail?.is_delivery && (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "1rem",
                          }}
                        >
                          <Text>Delivery Charge:</Text>
                          <Text>
                            ₹{orderDetail?.deliverycharge?.toFixed(2)}
                          </Text>
                        </Box>
                      )}
                      {!!orderDetail?.packaging_charge && (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "1rem",
                          }}
                        >
                          <Text>Packaging Charge:</Text>
                          <Text>
                            ₹{orderDetail?.packaging_charge?.toFixed(2)}
                          </Text>
                        </Box>
                      )}

                     {
                      orderDetail?.isgstapplied &&  <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <Text>Taxes:</Text>
                        <Text>₹{orderDetail?.foodGST?.toFixed(2)}</Text>
                      </Box>
                     }
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <Text>Convenience Fee:</Text>
                        <Text>
                          ₹{orderDetail?.convenienceTotalAmount?.toFixed(2)}
                        </Text>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <Text bold>
                          {orderDetail?.isGSTApplied
                            ? "Subtotal"
                            : "Subtotal + Taxes:"}
                        </Text>
                        <Text bold>
                          ₹{orderDetail?.totalPriceForCustomer?.toFixed(2)}
                        </Text>
                      </Box>
                    </Box>

                    {/* Radio Buttons for Order Type */}
                    <Box style={{ padding: "1.5rem" }}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="orderType"
                          name="orderType"
                          value={orderType}
                          onChange={handleOrderTypeChange}
                          row // Makes the radio buttons display in a row
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "1rem",
                              flexWrap: "wrap",
                            }}
                          >
                            <Box
                              sx={{
                                border: "1px solid #4F46E5",
                                borderRadius: "0.5rem",
                                padding: "0.3rem 1rem",
                              }}
                            >
                              <FormControlLabel
                                value="Take Away"
                                control={<Radio />}
                                label="Take Away"
                              />
                            </Box>

                            <Box
                              sx={{
                                border: "1px solid #4F46E5",
                                borderRadius: "0.5rem",
                                padding: "0.3rem 1rem",
                              }}
                            >
                              <FormControlLabel
                                value="Delivery"
                                control={<Radio />}
                                label="Delivery"
                              />
                            </Box>

                            <Box
                              sx={{
                                border: "1px solid #4F46E5",
                                borderRadius: "0.5rem",
                                padding: "0.3rem 1rem",
                              }}
                            >
                              <FormControlLabel
                                value="Dine In"
                                control={<Radio />}
                                label="Dine In"
                              />
                            </Box>
                          </Box>
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "1.4rem",
                        gap: "1rem",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#F9F7F5",
                          color: "black",
                          fontSize: "1rem",
                        }}
                        onClick={() => {
                          setOpenDetail(false);
                          setQuantities({});
                        }}
                        sx={{ textTransform: "none" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outlined"
                        style={{
                          fontSize: "1rem",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          setOrderOpen(true);
                        }}
                        sx={{ textTransform: "none" }}
                      >
                        Edit
                      </Button>
                      <Button
                        style={{
                          fontSize: "1rem",
                          backgroundColor: "#574eed",
                          color: "white",
                        }}
                        sx={{ textTransform: "none" }}
                        onClick={() => handlePrint(orderDetail)}
                      >
                        Print Kot
                      </Button>

                      <Button
                        style={{
                          fontSize: "1rem",
                          backgroundColor: "#574eed",
                          color: "white",
                        }}
                        sx={{ textTransform: "none" }}
                        onClick={handlePlaceOrder}
                      >
                        {" "}
                        <ReceiptIcon
                          style={{ fontSize: "1.8rem", marginRight: "0.5rem" }}
                        />
                        {loading ? "Ordering..." : "Place Order"}
                      </Button>
                    </Box>
                  </>
                )}
              </CustomDialog>
            )}
          </motion.div>
        ) : (
          <Publish />
        )}
      </>
      <iframe
        id="ifmcontentstoprint"
        style={{
          height: "0px",
          width: "0px",
          position: "absolute",
          visibility: "hidden",
        }}
      ></iframe>
      {/* <Bot outletId={outletIdNew} /> */}
    </>
  );
};

export default Dashboard;
