import React, { useState, useEffect, useRef } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import axios from "axios";
import {
  Box,
  Paper,
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Stack,
  Chip,
  Rating,
  Avatar,
  Button,
  Collapse,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ChatIcon from "@mui/icons-material/Chat";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useSelector } from "react-redux";

const Bot = ({ outletId }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [outletData, setOutletData] = useState(null);
  const [open, setOpen] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [showCustomers, setShowCustomers] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const chatEndRef = useRef(null);
  const [fabPosition, setFabPosition] = useState({ right: 16, bottom: 16 });
  const outletName = useSelector((state) => state.auth.user.outletName);
  const [expandedMessages, setExpandedMessages] = useState({});

  const suggestedQuestions = [
    "How can I improve my restaurant's efficiency?",
    "Show me all menu items",
    "What are the customer trends?", 
    "Show me all categories",
    "How many customers do we have?",
    "How to make Butter Chicken?",
  ];

  const genAI = new GoogleGenerativeAI(
    process.env.REACT_APP_GEMINI_API_KEY ||
      "AIzaSyDmRyeTQpZgGxQYuCez-dPMOXiskGZuswI"
  );

  useEffect(() => {
    outletId && fetchOutletData() && fetchCustomers();
  }, [outletId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchOutletData = async () => {
    try {
      const categoriesResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}outlet/menu/getParentCategory/${outletId}`
      );
      console.log("categoriesResponse", categoriesResponse);

      const itemsResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}outlet/menu/getItemList/${outletId}`
      );
      console.log("itemsResponse", itemsResponse);
      const combinedData = {
        name: outletName,
        categories: categoriesResponse?.data?.data || [],
        items: itemsResponse?.data?.data || [],
      };

      setOutletData(combinedData);
    } catch (error) {
      console.error("Error fetching outlet data:", error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}customer/getCustomer/${outletId}`
      );
      console.log("response", response);
      setCustomers(response.data.data || []);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const generateResponse = async (userInput) => {
    try {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });

      const lowerInput = userInput.toLowerCase();

      // Reset display states first
      setShowCategories(false);
      setShowItems(false);
      setShowCustomers(false);

      // Check for recipe related questions
      if (lowerInput.includes("how to make") || lowerInput.includes("how to cook") || lowerInput.includes("recipe")) {
        const itemName = lowerInput.replace("how to make", "")
                                 .replace("how to cook", "")
                                 .replace("recipe", "")
                                 .trim();
                                 
        const recipePrompt = `Give me a detailed recipe for ${itemName} including:
        1. List of ingredients with quantities
        2. Step by step cooking instructions
        3. Cooking time and servings
        Format the response with HTML tags for better readability.`;
        
        const recipeResult = await model.generateContent(recipePrompt);
        const recipeResponse = await recipeResult.response;
        return `<strong>Recipe for ${itemName}</strong>\n${recipeResponse.text()}`;
      }

      // Check for MealPe related questions
      if (
        lowerInput.includes("what is mealpe") ||
        lowerInput.includes("tell me about mealpe")
      ) {
        return `<strong>About MealPe</strong>
MealPe is an innovative food ordering company that offers a comprehensive POS solution for restaurants. Key features include:

• Advanced Order Scheduling
• Real-time Order Tracking
• Seamless Payment Integration
• Inventory Management
• Customer Analytics
• Multi-outlet Management
• Digital Menu Management
• Cloud-based Operations
• Customer Loyalty Programs
• Contactless Ordering Solutions`;
      }

      // Check for Maharsh Shah related questions
      if (
        lowerInput.includes("maharsh shah") ||
        lowerInput.includes("who is maharsh")
      ) {
        return `<strong>About Maharsh Shah</strong>
Maharsh Shah is the Founder, CEO, and CTO of MealPe Online Food Ordering POS Pvt. Ltd. As a visionary leader, he has been instrumental in revolutionizing the food ordering ecosystem through innovative technology solutions.`;
      }

      // Check for count related questions
      if (lowerInput.includes("count") || lowerInput.includes("how many")) {
        if (
          lowerInput.includes("category") ||
          lowerInput.includes("categories")
        ) {
          return `<strong>Total Categories</strong>: ${
            outletData?.categories?.length || 0
          }`;
        } else if (
          lowerInput.includes("item") ||
          lowerInput.includes("items")
        ) {
          return `<strong>Total Menu Items</strong>: ${
            outletData?.items?.length || 0
          }`;
        } else if (
          lowerInput.includes("customer") ||
          lowerInput.includes("customers")
        ) {
          return `<strong>Total Customers</strong>: ${customers?.length || 0}`;
        }
      }

      // Show categories, items or customers based on request
      if (
        lowerInput.includes("category") ||
        lowerInput.includes("categories")
      ) {
        setShowCategories(true);
        return "<strong>Categories</strong>\nHere are our available categories:";
      }
      if (lowerInput.includes("item") || lowerInput.includes("menu")) {
        setShowItems(true);
        return "<strong>Menu Items</strong>\nHere are our available menu items:";
      }
      if (lowerInput.includes("customer") || lowerInput.includes("customers")) {
        await fetchCustomers();
        setShowCustomers(true);
        return "<strong>Customer List</strong>\nHere are our valued customers:";
      }

      const context = `
        You are a helpful assistant for the restaurant "${outletName}". Format your responses with HTML-style formatting:
        - Use <strong> for bold text (e.g., <strong>Title</strong>)
        - Use bullet points for lists
        - Organize information under clear headings
        
        Categories:
        ${outletData?.categories
          ?.map((cat) => `• ${cat?.parentCategoryName}`)
          .join("\n")}

        Menu Items:
        ${outletData?.items
          ?.map((item) => `• ${item.itemname} - ₹${item.price}`)
          .join("\n")}

        Current Inventory Status: ${JSON.stringify(outletData?.inventory)}
      `;

      const prompt = `${context}\n\nUser Question: ${userInput}`;

      const result = await model.generateContent(prompt);
      const response = await result.response;
      return response.text();
    } catch (error) {
      console.error("Error generating response:", error);
      return "I apologize, but I'm having trouble processing your request right now.";
    }
  };

  const handleSend = async () => {
    if (!input.trim()) return;

    const userMessage = {
      text: input,
      sender: "user",
      timestamp: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setLoading(true);

    const botResponse = await generateResponse(input);

    setMessages((prev) => [
      ...prev,
      {
        text: botResponse,
        sender: "bot",
        timestamp: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      },
    ]);

    setLoading(false);
  };

  const handleSuggestionClick = async (question) => {
    setInput(question);
    const userMessage = {
      text: question,
      sender: "user", 
      timestamp: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setLoading(true);

    const botResponse = await generateResponse(question);

    setMessages((prev) => [
      ...prev,
      {
        text: botResponse,
        sender: "bot",
        timestamp: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      },
    ]);

    setLoading(false);
  };

  const toggleMessageExpansion = (index) => {
    setExpandedMessages(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const renderCategories = () => {
    if (!outletData?.categories?.length) {
      return null;
    }

    return (
      <List sx={{ mt: 2 }}>
        {outletData.categories.map((category) => (
          <ListItem
            key={category.parent_category_id}
            sx={{
              gap: 1.5,
              mb: 1.5,
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              transition: "transform 0.2s",
              padding: "12px",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
              },
            }}
          >
            <img
              src={category.parent_category_image_url}
              alt={category.parentCategoryName}
              style={{
                width: 50,
                height: 50,
                objectFit: "cover",
                borderRadius: "6px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            />
            <Stack spacing={1} sx={{ flex: 1 }}>
              <Typography variant="subtitle1" fontWeight="600">
                {category.parentCategoryName}
              </Typography>
              <Chip
                size="small"
                label={category.status ? "In Stock" : "Out of Stock"}
                sx={{
                  fontWeight: "600",
                  backgroundColor: category.status ? "#e8f5e9" : "#ffebee",
                  color: category.status ? "#2e7d32" : "#c62828",
                  borderRadius: "4px",
                  "& .MuiChip-label": {
                    padding: "4px 8px",
                  },
                }}
              />
            </Stack>
          </ListItem>
        ))}
      </List>
    );
  };

  const renderItems = () => {
    if (!outletData?.items?.length) {
      return null;
    }

    return (
      <List sx={{ mt: 2 }}>
        {outletData.items.map((item) => (
          <ListItem
            key={item.itemid}
            sx={{
              gap: 1.5,
              mb: 1.5,
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              transition: "transform 0.2s",
              padding: "12px",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
              },
            }}
          >
            <img
              src={item.item_image_url}
              alt={item.itemname}
              style={{
                width: 50,
                height: 50,
                objectFit: "cover",
                borderRadius: "6px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            />
            <Stack spacing={1} sx={{ flex: 1 }}>
              <Typography variant="subtitle1" fontWeight="600">
                {item.itemname}
              </Typography>
              <Typography variant="subtitle2" color="primary" fontWeight="600">
                ₹{item.price}
              </Typography>
              <Stack direction="row" spacing={0.5}>
                <Chip
                  size="small"
                  label={item.status ? "In Stock" : "Out of Stock"}
                  sx={{
                    fontWeight: "600",
                    backgroundColor: item.status ? "#e8f5e9" : "#ffebee",
                    color: item.status ? "#2e7d32" : "#c62828",
                    borderRadius: "4px",
                    "& .MuiChip-label": {
                      padding: "4px 8px",
                    },
                  }}
                />
                {item.isRecommendedItem && (
                  <Chip
                    size="small"
                    label="Recommended"
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#fff3e0",
                      color: "#e65100",
                      borderRadius: "4px",
                      "& .MuiChip-label": {
                        padding: "4px 8px",
                      },
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </ListItem>
        ))}
      </List>
    );
  };

  const renderCustomers = () => {
    if (!customers?.length) {
      return null;
    }

    return (
      <List sx={{ mt: 2 }}>
        {customers.map((customer) => (
          <ListItem
            key={customer.id}
            sx={{
              gap: 1.5,
              mb: 1.5,
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              transition: "transform 0.2s",
              padding: "12px",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
              },
            }}
          >
            <Avatar
              src={customer.photo}
              alt={customer.customername}
              sx={{ width: 50, height: 50 }}
            />
            <Stack spacing={0.5} sx={{ flex: 1 }}>
              <Typography variant="subtitle1" fontWeight="600">
                {customer.customername}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {customer.email}
              </Typography>
              <Rating
                size="small"
                value={customer.star}
                readOnly
                precision={0.5}
              />
            </Stack>
          </ListItem>
        ))}
      </List>
    );
  };

  const handleFabClick = (event) => {
    const fabRect = event.currentTarget.getBoundingClientRect();
    setFabPosition({ 
      right: window.innerWidth - fabRect.right,
      bottom: window.innerHeight - fabRect.top 
    });
    setOpen(true);
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="chat"
        onClick={handleFabClick}
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        <ChatIcon style={{height: 24, width: 24}}/>
      </Fab>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            position: 'fixed',
            right: fabPosition.right,
            bottom: fabPosition.bottom,
            m: 0,
            width: '400px',
            maxHeight: '80vh',
            borderRadius: "12px",
            backgroundColor: "#f8f9fa",
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid #e0e0e0",
            backgroundColor: "#fff",
          }}
        >
          Chat Assistant
        </DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Box
            sx={{ height: "60vh", display: "flex", flexDirection: "column" }}
          >
            <Paper
              elevation={0}
              sx={{
                flex: 1,
                p: 2,
                mb: 2,
                overflow: "auto",
                backgroundColor: "#f8f9fa",
              }}
            >
              {messages.length === 0 && (
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Suggested Questions:
                  </Typography>
                  <Stack spacing={1}>
                    {suggestedQuestions.map((question, index) => (
                      <Button
                        key={index}
                        variant="outlined"
                        size="small"
                        onClick={() => handleSuggestionClick(question)}
                        sx={{
                          justifyContent: "flex-start",
                          textTransform: "none",
                        }}
                      >
                        {question}
                      </Button>
                    ))}
                  </Stack>
                </Box>
              )}
              <List>
                {messages.map((message, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      justifyContent:
                        message.sender === "user" ? "flex-end" : "flex-start",
                      mb: 1,
                      display: "block",
                    }}
                  >
                    <Box sx={{ position: "relative" }}>
                      <Paper
                        sx={{
                          p: 1.5,
                          maxWidth: "70%",
                          backgroundColor:
                            message.sender === "user" ? "#e3f2fd" : "#fff",
                          marginLeft: message.sender === "user" ? "auto" : 0,
                          borderRadius: "12px",
                          borderTopRightRadius:
                            message.sender === "user" ? 0 : "12px",
                          borderTopLeftRadius:
                            message.sender === "user" ? "12px" : 0,
                          wordBreak: "break-word",
                        }}
                      >
                        <Collapse in={expandedMessages[index] || message.text.length <= 150} collapsedSize="80px">
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "pre-wrap",
                              "& strong": { fontWeight: 600 },
                            }}
                            dangerouslySetInnerHTML={{ __html: message.text }}
                          />
                        </Collapse>
                        {message.text.length > 150 && (
                          <Button
                            size="small"
                            onClick={() => toggleMessageExpansion(index)}
                            endIcon={expandedMessages[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            sx={{ mt: 1 }}
                          >
                            {expandedMessages[index] ? "Read Less" : "Read More"}
                          </Button>
                        )}
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                          sx={{
                            justifyContent: "flex-end",
                            mt: 0.5,
                          }}
                        >
                          <AccessTimeIcon
                            sx={{ fontSize: 12, color: "text.secondary" }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {message.timestamp}
                          </Typography>
                        </Stack>
                      </Paper>
                    </Box>
                    {message.sender === "bot" &&
                      index === messages.length - 1 && (
                        <>
                          {showCategories && renderCategories()}
                          {showItems && renderItems()}
                          {showCustomers && renderCustomers()}
                        </>
                      )}
                  </ListItem>
                ))}
                {loading && (
                  <ListItem sx={{ justifyContent: "flex-start" }}>
                    <CircularProgress size={20} />
                  </ListItem>
                )}
                <div ref={chatEndRef} />
              </List>
            </Paper>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {messages.length > 0 && (
                <Box 
                  sx={{ 
                    display: "flex", 
                    overflowX: "auto",
                    pb: 1,
                    "&::-webkit-scrollbar": {
                      height: "6px"
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "3px"
                    }
                  }}
                >
                  {suggestedQuestions.map((question, index) => (
                    <Chip
                      key={index}
                      label={question}
                      onClick={() => handleSuggestionClick(question)}
                      sx={{
                        mr: 1,
                        whiteSpace: "nowrap",
                        backgroundColor: "#e3f2fd",
                        "&:hover": {
                          backgroundColor: "#bbdefb",
                        }
                      }}
                    />
                  ))}
                </Box>
              )}
              <Box sx={{ display: "flex", gap: 1 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Ask about menu items, recipes, categories, customers or inventory..."
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleSend()}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                      borderRadius: "12px",
                    },
                  }}
                />
                <IconButton
                  color="primary"
                  onClick={handleSend}
                  disabled={loading}
                  sx={{
                    backgroundColor: "#e3f2fd",
                    borderRadius: "50%",
                    width: 48,
                    height: 48,
                    "&:hover": {
                      backgroundColor: "#bbdefb",
                    },
                  }}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Bot;
